export enum VIEWS {
  // PUBLIC ROUTES
  HOME = "/",
  HOME_WITH_PREFIX = "/home",
  SIGN_UP = "/sign-up",
  SIGN_IN = "/sign-in",
  VERIFY_OTP = "/verify-otp",
  FORGOT_PASSWORD = "/forgot-password",
  RESET_PASSWORD = "/reset-password",
  ABOUT = "/about",
  PRICING = "/pricing",
  PRIVACY_POLICY = "/privacy-policy",
  TERMS_CONDITIONS = "/terms-and-conditions",
  HELP = "/help",

  // USER ROUTES
  USER_DASHBOARD_LAYOUT = "/user",
  USER_DASHBOARD = "/user/dashboard",
  USER_DOCUMENTS = "/user/documents",
  USER_SETTINGS = "/user/settings",
  USER_SUBSCRIPTIONS = "/user/subscriptions",
  USER_PAYMENT_SUCCESS = "/user/subscriptions/success",
  USER_PAYMENT_CANCEL = "/user/subscriptions/cancel",
  USER_PRIVACY_POLICY = "/user/privacy-policy",
  USER_TERMS_CONDITIONS = "/user/terms-and-conditions",
  USER_HELP = "/user/help",
}
