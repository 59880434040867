import React, { useEffect, useState } from "react";
import LoadingSpinner from "../../components/loading-spinner.component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import { VIEWS } from "../../routes/routes";
import { useScrollToTop } from "../../common/hooks/use-scroll-to-top";
import { useAuth } from "../../common/providers/user.provider";

export default function PaymentCancel() {
  useScrollToTop();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <React.Fragment>
      <h1 className="text-2xl md:text3-xl lg:text-4xl xl:text-5xl text-slate-700 font-bold">
        <span
          onClick={() => navigate(-1)}
          className="cursor-pointer mr-2 lg:mr-10"
        >
          <i className="fa-solid fa-chevron-left"></i>
        </span>
        Payment status
      </h1>
      <div className="bg-white p-6 md:mx-auto mt-32">
        <div className="text-center">
          <i className="fa-solid fa-circle-xmark text-8xl text-rose-400 mb-10"></i>
          <h3 className="md:text-2xl text-base text-gray-900 font-semibold text-center">
            Payment cancel!
          </h3>
          <p className="text-gray-400 my-2">Please try again</p>
          <div className="py-10 text-center">
            <Link
              to={VIEWS.USER_SUBSCRIPTIONS}
              className="py-5 px-12 bg-blue-600 hover:bg-blue-700 text-white font-semibold rounded-lg text-sm"
            >
              Go to Pricing Plans
            </Link>
          </div>
        </div>
      </div>
      <div className="h-32"></div>
      {isLoading ? <LoadingSpinner /> : null}
      <ToastContainer />
    </React.Fragment>
  );
}
