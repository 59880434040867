import React, { useEffect, useState } from "react";
import { Link, useNavigate, redirect, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingSpinner from "../../components/loading-spinner.component";
import { useScrollToTop } from "../../common/hooks/use-scroll-to-top";
import TopNavbar from "../../components/top-navbar.component";
import InputField from "../../components/input-field.component";
import Button from "../../components/button.component";
import { VIEWS } from "../../routes/routes";
import Footer from "../../components/footer.component";
import coverImage from "../../common/assets/images/reset_password.png";
import { resetPassword } from "../../services/auth-api.service";
import useToken from "../../hooks/useToken";

export default function ResetPassword() {
  useScrollToTop();
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { token, setToken, clearToken } = useToken();

  //Getting the token from the URL params
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const accessToken: string = searchParams.get("token") ?? "";

  //If the token is available public pages are unavailable
  useEffect(() => {
    if (token) {
      navigate(VIEWS.USER_DASHBOARD);
    }
  }, [token]);

  useEffect(() => {
    if (accessToken === "") {
      navigate(VIEWS.SIGN_IN);
    }
  }, [accessToken]);

  const handleFormAction = async () => {
    if (accessToken === "") {
      toast.error("Invalid reset password link");
    } else if (password === "") {
      toast.error("Please enter new password");
    } else if (password !== confirmPassword) {
      toast.error("Confirm password is not match");
    } else {
      try {
        setIsLoading(true);
        const response = await resetPassword(
          accessToken,
          password,
          confirmPassword
        );
        if (response.success) {
          console.log("RESPONSE", response);
          setPassword("");
          setConfirmPassword("");
          setIsLoading(false);
          navigate(VIEWS.SIGN_IN);
        } else {
          setIsLoading(false);
          toast.error(response.error);
        }
      } catch (error: any) {
        setIsLoading(false);
      }
    }
  };

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      handleFormAction();
    }
  };

  return (
    <React.Fragment>
      <div className="flex flex-col min-h-screen">
        <TopNavbar
          btnName={"Sign In"}
          btnAction={() => navigate(VIEWS.SIGN_IN)}
        />
        <div className="w-full flex flex-col md:flex-row justify-center items-center min-h-screen mt-20">
          {/* Left side container */}
          <div className="w-full md:w-full h-screen bg-rose-300 relative hidden md:flex justify-center items-center">
            <button
              onClick={() => navigate(-1)}
              className="text-white absolute top-16 left-16 text-4xl"
            >
              <i className="fa-solid fa-chevron-left"></i>
            </button>
            <img src={coverImage} alt="" className="w-2/3 h-auto" />{" "}
          </div>
          {/* Right side container */}
          <div className="w-full md:w-3/4 h-screen bg-white flex justify-center items-start">
            <div className="w-full bg-light px-5 pt-10 pb-20 rounded-xl mx-10 sm:mx-20 md:mx-5 lg:mx-20 xl:mx-32 mt-20">
              <h1 className="text-slate-800 font-bold text-center text-3xl my-14">
                Reset password
              </h1>
              <p className="block text-gray-700 text-sm font-normal text-center my-8 leading-6">
                Set a new password that’s long, strong, and <br />
                memorable.
              </p>
              {/* <p className="block text-gray-700 text-sm font-bold text-center mt-4 mb-10">
                useremail@sample.com
              </p> */}
              <InputField
                icon="fa-solid fa-lock"
                type="password"
                label="New password *"
                placeholder="Enter new password"
                value={password}
                onChange={setPassword}
                onKeyDown={handleKeyPress}
              />
              <InputField
                icon="fa-solid fa-lock"
                type="password"
                label="Confirm password *"
                placeholder="Re-enter your password"
                value={confirmPassword}
                onChange={setConfirmPassword}
                onKeyDown={handleKeyPress}
              />
              <Button
                name={"Save new password"}
                handleAction={handleFormAction}
              />
            </div>
          </div>
        </div>
        <Footer />
      </div>
      {isLoading ? <LoadingSpinner /> : null}
      <ToastContainer />
    </React.Fragment>
  );
}
