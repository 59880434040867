import React, { useCallback, useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { createDocument } from "../../services/document-api.service";
import { useAuth } from "../../common/providers/user.provider";
import FormInputField from "../../components/form-input.component";
import Button from "../../components/button.component";
import LoadingSpinner from "../../components/loading-spinner.component";
import SelectField from "../../components/select-field.component";
import { ReminderValues } from "../../common/enums/reminder-date.enum";
import FormTextField from "../../components/form-text.component";
import { DropEvent, FileRejection, useDropzone } from "react-dropzone";
import { useScrollToTop } from "../../common/hooks/use-scroll-to-top";
import { useNavigate } from "react-router-dom";
import { VIEWS } from "../../routes/routes";
import { PricingPlans } from "../../common/enums/pricing-plan.enum";

export default function Dashboard() {
  useScrollToTop();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [formData, setFormData] = useState({
    file: null as File | null,
    receiverEmail: "",
    dueDate: "",
    reminder: "",
    message: "",
    fileName: "",
  });
  const checkEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const [isLoading, setIsLoading] = useState(false);

  //Handling the select options
  const [selectedOption, setSelectedOption] = useState("");
  const options = Object.values(ReminderValues).map((value) => ({
    value: value,
    label: value,
  }));
  const handleSelectChange = (value: string) => {
    setSelectedOption(value);
  };

  //Handling the text input
  const handleInputChange = (name: string, value: any) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // FILE UPLOADING FUNCTIONALITIES GOES HERE===============================================================
  interface FileWithPreview extends File {
    preview: string;
  }

  //To selecting the single file only
  const [files, setFiles] = useState<FileWithPreview[]>([]);
  const [rejected, setRejected] = useState<File[]>([]);
  const [dropzoneDisabled, setDropzoneDisabled] = useState(false);

  // Define constants for file size limits
  const FREE_USER_FILE_LIMIT = 30 * 1024 * 1024; // 30 MB in bytes
  const PRO_USER_FILE_LIMIT = 100 * 1024 * 1024; // 100 MB in bytes
  const userSubscription = user?.pricingPlan;

  const onDrop = useCallback(
    (
      acceptedFiles: File[],
      fileRejections: FileRejection[],
      event: DropEvent
    ) => {
      // Check if the dropzone is disabled (file already selected)
      if (dropzoneDisabled) {
        return;
      }

      // Ensure only one file is accepted
      if (acceptedFiles?.length === 1) {
        const file = acceptedFiles[0];

        // Checking the file size limitations
        const fileSize = file.size;
        const maxFileSize =
          userSubscription === PricingPlans.FREE
            ? FREE_USER_FILE_LIMIT
            : PRO_USER_FILE_LIMIT;
        if (fileSize > maxFileSize) {
          toast.error(
            `File size exceeds the limit. Maximum allowed file size is ${
              maxFileSize / (1024 * 1024)
            } MB for ${userSubscription} users.`
          );
          return;
        }

        setFiles((previousFiles) => [
          ...previousFiles,
          {
            ...acceptedFiles[0],
            preview: URL.createObjectURL(acceptedFiles[0]),
          },
        ]);

        // Read the file content as binary
        const reader = new FileReader();
        reader.onload = (e) => {
          const binaryData = e.target?.result;

          // Convert ArrayBuffer to Blob
          const blob = new Blob([binaryData as ArrayBuffer], {
            type: file.type,
          });

          // Update state with the file
          setFormData({
            ...formData,
            file: file,
            fileName: file.name,
          });

          // Append the Blob to FormData
          const formDataToSend = new FormData();
          formDataToSend.append("file", file);
          formDataToSend.append("receiverEmail", formData.receiverEmail);
          formDataToSend.append("dueDate", formData.dueDate);
          formDataToSend.append("reminder", formData.reminder);
          formDataToSend.append("message", formData.message);
          formDataToSend.append("fileName", formData.fileName);

          // Use formDataToSend in your API call
          console.log("formDataToSend:", formDataToSend);

          // Disable the dropzone after selecting a file
          setDropzoneDisabled(true);
        };

        // Read file content as binary
        reader.readAsArrayBuffer(file);
      }

      // Handle other rejected files
      if (fileRejections?.length) {
        setRejected((previousFiles) => [
          ...previousFiles,
          ...fileRejections.map((rejection) => rejection.file),
        ]);
      }
    },
    [dropzoneDisabled, formData, setRejected]
  );

  const removeFile = (name: string) => {
    setFiles((files) => files.filter((file) => file.name !== name));
    // Enable the dropzone after removing the file
    setDropzoneDisabled(false);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      "application/pdf": [".pdf", ".PDF"],
    },
    maxFiles: 1,
    onDrop,
  });

  useEffect(() => {
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  const handleFormAction = async (e: any) => {
    e.preventDefault();
    const { file, receiverEmail, dueDate, reminder, message, fileName } =
      formData;
    if (!file) {
      toast.error("Please select a document");
    } else if (receiverEmail === "") {
      toast.error("Please enter receiver email");
    } else if (!checkEmail.test(receiverEmail)) {
      toast.error("Please enter valid receiver email");
    } else if (user?.pricingPlan !== PricingPlans.PRO && dueDate === "") {
      toast.error("Please select due date");
    } else if (
      user?.pricingPlan === PricingPlans.FREE &&
      new Date(dueDate) > new Date(calculateMaxDueDate())
    ) {
      toast.error(
        "Upgrade to Pro to set due dates longer ahead than one month"
      );
    } else if (message === "") {
      toast.error("Please write your review instructions");
    } else {
      try {
        setIsLoading(true);

        // // Check if the Geolocation API is available in the browser
        // if ("geolocation" in navigator) {
        //   navigator.geolocation.getCurrentPosition(
        //     async (position) => {
        //       const senderEmail = user?.email as string;
        //       const isTermsAndConditionsAccepted = true;
        //       const latitude = position.coords.latitude.toString();
        //       const longitude = position.coords.longitude.toString();
        //       const reminder =
        //         selectedOption === ReminderValues.NoReminder
        //           ? ""
        //           : selectedOption;

        //       // Create FormData object to handle file upload
        //       const formData = new FormData();
        //       formData.append("file", file);
        //       formData.append("senderEmail", senderEmail);
        //       formData.append("receiverEmail", receiverEmail);
        //       formData.append("dueDate", dueDate);
        //       formData.append("reminder", reminder);
        //       formData.append("message", message);
        //       formData.append(
        //         "isTermsAndConditionsAccepted",
        //         isTermsAndConditionsAccepted.toString()
        //       );
        //       formData.append("latitude", latitude);
        //       formData.append("longitude", longitude);
        //       formData.append("fileName", fileName);

        //       // Calling to the API service
        //       const response = await createDocument(formData);

        //       // Handling the response
        //       if (response.success) {
        //         setFormData({
        //           file: null,
        //           receiverEmail: "",
        //           dueDate: "",
        //           reminder: "",
        //           message: "",
        //           fileName: "",
        //         });
        //         setSelectedOption("");
        //         setFiles([]);
        //         window.scrollTo(0, 0);
        //         toast.success(response?.data?.message);
        //         setTimeout(() => {
        //           navigate(VIEWS.USER_DOCUMENTS);
        //         }, 2000);
        //       } else {
        //         toast.error(response.error);
        //       }
        //       setIsLoading(false);
        //     },
        //     (error) => {
        //       console.error("Error getting user location:", error);
        //       setIsLoading(false);
        //     }
        //   );
        // } else {
        //   console.error("Geolocation is not supported");
        //   setIsLoading(false);
        // }

        const senderEmail = user?.email as string;
        const isTermsAndConditionsAccepted = true;
        // const latitude = position.coords.latitude.toString();
        // const longitude = position.coords.longitude.toString();
        const reminder =
          selectedOption === ReminderValues.NoReminder ? "" : selectedOption;

        // Create FormData object to handle file upload
        const formData = new FormData();
        formData.append("file", file);
        formData.append("senderEmail", senderEmail);
        formData.append("receiverEmail", receiverEmail);
        formData.append("dueDate", dueDate);
        formData.append("reminder", reminder);
        formData.append("message", message);
        formData.append(
          "isTermsAndConditionsAccepted",
          isTermsAndConditionsAccepted.toString()
        );
        // formData.append("latitude", latitude);
        // formData.append("longitude", longitude);
        formData.append("fileName", fileName);

        // Calling to the API service
        const response = await createDocument(formData);

        // Handling the response
        if (response.success) {
          setFormData({
            file: null,
            receiverEmail: "",
            dueDate: "",
            reminder: "",
            message: "",
            fileName: "",
          });
          setSelectedOption("");
          setFiles([]);
          window.scrollTo(0, 0);
          toast.success(response?.data?.message);
          setTimeout(() => {
            navigate(VIEWS.USER_DOCUMENTS);
          }, 2000);
        } else {
          toast.error(response.error);
        }
        setIsLoading(false);
      } catch (error) {
        console.error("Error during form action:", error);
        setIsLoading(false);
      }
    }
  };

  // Set the max due date as 1 month ahead
  const calculateMaxDueDate = () => {
    const today = new Date();
    const oneMonthAhead = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      today.getDate()
    );
    const maxDueDate = oneMonthAhead.toISOString().split("T")[0];
    return maxDueDate;
  };

  return (
    <React.Fragment>
      <h1 className="text-5xl text-slate-700 font-bold">Create Review</h1>
      <form className="w-full bg-blue-50 p-5 rounded-xl my-10">
        <div>
          <div
            {...getRootProps({
              className: "custom-class",
            })}
          >
            <input {...getInputProps()} accept="application/pdf" />
            <div className="flex flex-col items-center justify-center gap-4">
              {isDragActive ? (
                <div className="flex flex-col items-center justify-center py-5 h-36 text-center bg-white w-full rounded-xl cursor-pointer border-2 border-blue-600 border-dashed">
                  <i className="fa-solid fa-cloud-arrow-up text-blue-600 text-6xl mt-5" />
                  <p className="text-slate-400 text-sm mt-1">
                    Drop the PDF here ...
                  </p>
                </div>
              ) : (
                <div className="flex flex-col items-center justify-center py-5 h-36 text-center bg-white w-full rounded-xl cursor-pointer border-2 border-gray-300 border-dashed">
                  <i className="fa-solid fa-cloud-arrow-up text-slate-200 text-6xl mt-5" />
                  <p className="text-slate-400 text-sm mt-1">
                    Drag and drop or click to upload PDF file
                  </p>
                </div>
              )}
            </div>
          </div>
          <section className="mt-2 mb-10">
            {files.map((file: any, index: any) => (
              <div
                key={index.toString()}
                className="w-full px-5 py-3 rounded-lg shadow-xm bg-slate-50 text-slate-700 flex flex-row items-center justify-between my-2"
              >
                <div className="w-full flex flex-row items-center justify-start">
                  <i className="fa-solid fa-file-pdf text-2xl" />
                  <p className="text-slate-700 text-sm font-medium ml-2">
                    {file?.path}
                  </p>
                </div>
                <button
                  type="button"
                  className="w-7 h-7 flex justify-center items-center transition-colors cursor-pointer"
                  onClick={() => removeFile(file.name)}
                >
                  <i className="fa-regular fa-circle-xmark text-2xl" />
                </button>
              </div>
            ))}
          </section>
        </div>
        <div className="flex flex-col lg:flex-row items-center justify-between w-full">
          <div className="w-full lg:mr-5">
            <FormInputField
              icon="fa-solid fa-envelope"
              type="email"
              label="Your email *"
              placeholder="Enter your email"
              name="senderEmail"
              value={user?.email}
              onChange={(data: { name: string; value: any }) =>
                handleInputChange(data.name, data.value)
              }
              disabled={true}
            />
          </div>
          <div className="w-full">
            <FormInputField
              icon="fa-solid fa-envelope"
              type="email"
              label="Receiver email *"
              placeholder="Enter receiver email"
              name="receiverEmail"
              value={formData.receiverEmail}
              onChange={(data: { name: string; value: any }) =>
                handleInputChange(data.name, data.value)
              }
            />
          </div>
        </div>
        <div className="flex flex-col lg:flex-row items-center justify-between w-full">
          <div className="w-full lg:mr-5">
            <FormInputField
              icon="fa-regular fa-calendar-days"
              type="date"
              label={`Select the due date ${
                user?.pricingPlan === PricingPlans.FREE ? "*" : ""
              }`}
              placeholder="Due date"
              name="dueDate"
              value={formData.dueDate}
              onChange={(data: { name: string; value: any }) =>
                handleInputChange(data.name, data.value)
              }
              max={
                user?.pricingPlan === PricingPlans.FREE
                  ? calculateMaxDueDate()
                  : null
              }
            />
          </div>
          <div className="w-full lg:w-1/3">
            <SelectField
              icon="fa-solid fa-clock"
              options={options}
              value={selectedOption}
              onChange={handleSelectChange}
              disabled={false}
              label="Select the reminder (Optional)"
              placeholder={"Select Reminder"}
            />
          </div>
        </div>
        <FormTextField
          label="Review instructions *"
          name="message"
          value={formData.message}
          onChange={(data: { name: string; value: any }) =>
            handleInputChange(data.name, data.value)
          }
          rows={3}
        />
        <Button name={"Submit request"} handleAction={handleFormAction} />
      </form>
      <div className="h-32"></div>
      {isLoading ? <LoadingSpinner /> : null}
      <ToastContainer />
    </React.Fragment>
  );
}
