import React, { useEffect, useState } from "react";

const OtpInputField = (props: { onChange: any; onKeyDown: any }) => {
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);

  useEffect(() => {
    const otpString = otp.join("");
    const otpNumber = parseInt(otpString, 10);
    props.onChange(otpNumber);
  }, [otp]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const value = e.target.value;

    // Allow only numeric input and limit to one digit
    if (/^\d*$/.test(value) && value.length <= 1) {
      const newOtp = [...otp];
      newOtp[index] = value;

      // Automatically move to the next input field if there is a value
      if (value && index < otp.length - 1) {
        const nextInput = document.getElementById(`otp-input-${index + 1}`);
        if (nextInput) {
          nextInput.focus();
        }
      }

      setOtp(newOtp);
    }
  };

  const handleBackspace = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    // Automatically move to the previous input field on backspace if the current field is empty
    if (e.key === "Backspace" && index > 0 && !otp[index]) {
      const prevInput = document.getElementById(`otp-input-${index - 1}`);
      if (prevInput) {
        prevInput.focus();
      }
    }

    // Trigger the onKeyDown function when the "Enter" key is pressed
    if (e.key === "Enter") {
      props.onKeyDown(e);
    }
  };

  // Handling the OTP copy from the email and paste it into the otp fields
  const handlePaste = (e: any, index: number) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text");
    if (/^\d*$/.test(pastedData) && pastedData.length <= 6 - index) {
      const newOtp = [...otp];
      for (let i = 0; i < pastedData.length; i++) {
        newOtp[index + i] = pastedData.charAt(i);
      }
      setOtp(newOtp);

      // Set focus to the last input field after pasting
      const lastInput = document.getElementById(
        `otp-input-${Math.min(index + pastedData.length, 5)}`
      );
      if (lastInput) {
        lastInput.focus();
      }
    }
  };

  return (
    <div className="flex w-full justify-center items-center">
      {otp.map((digit, index) => (
        <input
          key={index}
          id={`otp-input-${index}`}
          type="text"
          maxLength={1}
          value={digit}
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleBackspace(e, index)}
          onPaste={(e) => handlePaste(e, index)}
          className={`w-10 sm:w-16 md:w-20 h-12 sm:h-16 text-lg sm:text-2xl border border-gray-200 bg-white focus:ring focus:bg-slate-50 ring-blue-500 mx-1 text-center rounded-lg font-bold text-slate-600`}
        />
      ))}
    </div>
  );
};

export default OtpInputField;
