import React, { useEffect, useState } from "react";
import { Link, useNavigate, redirect, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingSpinner from "../../components/loading-spinner.component";
import { useScrollToTop } from "../../common/hooks/use-scroll-to-top";
import TopNavbar from "../../components/top-navbar.component";
import InputField from "../../components/input-field.component";
import Button from "../../components/button.component";
import { VIEWS } from "../../routes/routes";
import Footer from "../../components/footer.component";
import coverImage from "../../common/assets/images/sign_up.png";
import { generateOtp, verifyOtp } from "../../services/auth-api.service";
import useToken from "../../hooks/useToken";
import OtpInputField from "../../components/otp-input-field.component";

export default function VerifyOtp() {
  useScrollToTop();
  const navigate = useNavigate();
  const [otp, setOtp] = useState<number | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const { token, setToken, clearToken } = useToken();

  //Getting the token from the URL params
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const email: string = searchParams.get("email") ?? "";

  useEffect(() => {
    if (email === "") {
      navigate(VIEWS.SIGN_IN);
    }
  }, []);

  //If the token is available public pages are unavailable
  useEffect(() => {
    if (token) {
      navigate(VIEWS.USER_DASHBOARD);
    }
  }, [token]);

  const handleResendOtp = async () => {
    try {
      setIsLoading(true);
      const response = await generateOtp(email);
      if (response.success) {
        console.log("RESPONSE", response);
        setIsLoading(false);
        toast.success(response.data?.message);
      } else {
        setIsLoading(false);
        toast.error(response.error);
      }
    } catch (error: any) {
      setIsLoading(false);
    }
  };

  const handleFormAction = async () => {
    if (otp === undefined) {
      toast.error("Please enter OTP");
    } else {
      try {
        setIsLoading(true);
        const response = await verifyOtp(email, otp);
        if (response.success) {
          setIsLoading(false);
          setOtp(undefined);
          toast.success(response.data?.message);
          setTimeout(() => {
            navigate(VIEWS.SIGN_IN);
          }, 2000);
        } else {
          setIsLoading(false);
          toast.error(response.error);
        }
      } catch (error: any) {
        setIsLoading(false);
      }
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event && event.key === "Enter") {
      handleFormAction();
    }
  };

  return (
    <React.Fragment>
      <div className="flex flex-col min-h-screen px-10 md:px-0">
        <TopNavbar
          btnName={"Sign In"}
          btnAction={() => navigate(VIEWS.SIGN_IN)}
        />
        <div className="w-full flex flex-col md:flex-row justify-center items-center min-h-screen mt-20">
          {/* Left side container */}
          <div className="w-full md:w-full h-screen bg-amber-300 relative hidden md:flex justify-center items-center">
            <button
              onClick={() => navigate(-1)}
              className="text-white absolute top-16 left-16 text-4xl"
            >
              <i className="fa-solid fa-chevron-left"></i>
            </button>
            <img src={coverImage} alt="" className="w-2/3 h-auto" />{" "}
          </div>
          {/* Right side container */}
          <div className="w-full md:w-3/4 h-screen bg-white flex justify-center items-start">
            <div className="w-full bg-light p-10 rounded-xl mx-10 sm:mx-20 md:mx-5 lg:mx-20 xl:mx-32 mt-20">
              <h1 className="text-slate-800 font-bold text-center text-3xl my-14">
                Activate My Account
              </h1>
              <p className="block text-gray-700 text-sm font-bold text-center mt-4 mb-10">
                {email ? email : ""}
              </p>
              <p className="block text-gray-400 text-xs text-center my-6">
                Please enter the 6-digit one-time code you received in your
                email.
              </p>
              <OtpInputField onChange={setOtp} onKeyDown={handleKeyPress} />
              <div className="flex items-center justify-center">
                <button
                  onClick={handleResendOtp}
                  className="w-fit text-sm font-semibold text-center mt-10 mb-5 text-blue-800 hover:underline cursor-pointer"
                >
                  Resend one-time code
                </button>
              </div>
              <Button
                name={"Verify your account"}
                handleAction={handleFormAction}
              />
            </div>
          </div>
        </div>
        <Footer />
      </div>
      {isLoading ? <LoadingSpinner /> : null}
      <ToastContainer />
    </React.Fragment>
  );
}
