import React, { ChangeEvent, useEffect, useState } from "react";
import Button from "../../button.component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingSpinner from "../../loading-spinner.component";
import useToken from "../../../hooks/useToken";
import { updateUserProfileImage } from "../../../services/user-api.service";
import { useAuth } from "../../../common/providers/user.provider";
import defaultPlaceholder from "../../../common/assets/images/logo-placeholder.png";

export default function ProfileImageUploadForm(props: {}) {
  const { user } = useAuth();
  const { token, setToken, clearToken } = useToken();
  const [file, setFile] = useState<File>();
  const [isLoading, setIsLoading] = useState(false);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const handleEditProfileImage = async () => {
    if (!file) {
      toast.error("Please select a image file");
      return;
    } else {
      try {
        setIsLoading(true);
        const formData = new FormData();
        formData.append("file", file);
        const response = await updateUserProfileImage(
          token,
          user?.id || "",
          formData
        );
        if (response.success) {
          setIsLoading(false);
          window.location.reload();
        } else {
          setIsLoading(false);
          toast.error(response.error);
        }
      } catch (error) {
        setIsLoading(false);
        console.error(error);
      }
    }
  };

  return (
    <React.Fragment>
      <div className="w-full h-fit bg-blue-50 p-5 lg:p-10 rounded-xl my-10">
        <h1 className="text-xl font-semibold mt-5 text-slate-600">
          Upload logo
        </h1>
        <div className="flex flex-col justify-center items-center md:flex-row mb-12">
          <span className="w-full lg:w-3/4 mt-5">
            <p className="text-sm text-slate-500 font-semibold mb-2">
              Select your image file (jpg, jpeg, png). Pro users have their logo
              placed in the header of the review invitation emails.
            </p>
            <input
              type="file"
              accept=".jpg, .jpeg, .png"
              onChange={handleFileChange}
            />
          </span>
          <span className="w-full mt-5 flex justify-center items-center">
            <img
            className="w-auto h-64"
              src={
                user?.profileImage !== null
                  ? user?.profileImageUrl
                  : defaultPlaceholder
              }
            ></img>
          </span>
        </div>
        <div className="w-full md:w-1/2 lg:w-2/5 mt-5">
          <Button name={"Save Changes"} handleAction={handleEditProfileImage} />
        </div>
      </div>
      {isLoading ? <LoadingSpinner /> : null}
      <ToastContainer />
    </React.Fragment>
  );
}
