import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  ReactNode,
} from "react";
import { getLoggedInUser } from "../../services/auth-api.service";
import useToken from "../../hooks/useToken";

interface User {
  id: string;
  firstName: string;
  lastName: string;
  company: string;
  email: string;
  role: string;
  isAccountActivate: boolean;
  profileImage: string | null;
  profileImageUrl: string | null;
  pricingPlan: string;
}

interface AuthContextProps {
  user: User | null;
  updateUser: (userData: User) => void;
}

export const AuthContext = createContext<AuthContextProps | null>(null);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = useState<User | null>(null);
  const { token, setToken, clearToken } = useToken();

  const fetchUserDetails = async (token: string) => {
    try {
      const response = await getLoggedInUser(token);
      if (response.success) {
        const userData: User = response?.data?.data;
        setUser(userData);
      } else {
        console.log(response.error);
      }
    } catch (error: any) {
      console.error("Error fetching user details:", error);
    }
  };

  useEffect(() => {
    if (token) {
      fetchUserDetails(token);
    }
  }, [token]);
  console.log("CHECK DATA : ", user)

  const updateUser = (userData: User) => {
    setUser(userData);
  };

  const contextValue: AuthContextProps = {
    user,
    updateUser,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextProps => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
};
