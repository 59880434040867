import React, { useEffect, useState } from "react";
import LoadingSpinner from "../../components/loading-spinner.component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import { VIEWS } from "../../routes/routes";
import { useScrollToTop } from "../../common/hooks/use-scroll-to-top";
import { useAuth } from "../../common/providers/user.provider";
import { PricingPlans } from "../../common/enums/pricing-plan.enum";
import {
  cancelSubscription,
  createCheckout,
  createCustomer,
} from "../../services/payment-api.service";
import useToken from "../../hooks/useToken";
import { getAllSubscriptionPlans } from "../../services/subcriptions-api.service";
import PopupConfirmationComponent from "../../components/popup-confirmation.component";

export default function Subscriptions() {
  useScrollToTop();
  const { user } = useAuth();
  const navigate = useNavigate();
  const { token, setToken, clearToken } = useToken();
  const [isLoading, setIsLoading] = useState(false);
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);

  useEffect(() => {
    handleGetAllSubscriptionPlans();
  }, []);

  const handleGetAllSubscriptionPlans = async () => {
    try {
      setIsLoading(true);
      // Calling to the API service
      const response = await getAllSubscriptionPlans();
      // Handling the response
      if (response.success) {
        console.log("CHECK DATA : ", response?.data);
        setSubscriptionPlans(response?.data?.data);
      } else {
        toast.error(response.error);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error during form action:", error);
      setIsLoading(false);
    }
  };

  const handleCheckoutSession = async (priceId: string) => {
    try {
      setIsLoading(true);
      const response = await createCustomer(token);
      if (response.success) {
        const responseCheckout = await createCheckout(token, priceId);
        if (responseCheckout.success) {
          setIsLoading(false);
          window.location.href = responseCheckout?.data?.data;
        } else {
          setIsLoading(false);
          toast.error(response.error);
        }
      } else {
        setIsLoading(false);
        toast.error(response.error);
      }
    } catch (error: any) {
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const handleConfirmCancelSubscription = async () => {
    setConfirmationOpen(true);
  };

  const handleCancelSubscription = async () => {
    try {
      setConfirmationOpen(false);
      setIsLoading(true);
      const response = await cancelSubscription(token);
      if (response.success) {
        toast.success(response?.data?.message);
        setTimeout(() => {
          window.location.href = VIEWS.USER_DASHBOARD;
        }, 2500);
      } else {
        setIsLoading(false);
        toast.error(response.error);
      }
    } catch (error: any) {
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  return (
    <React.Fragment>
      <h1 className="text-2xl md:text3-xl lg:text-4xl xl:text-5xl text-slate-700 font-bold">
        <span
          onClick={() => navigate(-1)}
          className="cursor-pointer mr-2 lg:mr-10"
        >
          <i className="fa-solid fa-chevron-left"></i>
        </span>
        Billing and Subscription
      </h1>
      <div className="w-fit flex flex-col justify-center items-start my-5 lg:my-20">
        <Link
          to={"https://billing.stripe.com/p/login/dR6bM8fmD3JzbJu8ww"}
          target={"_blank"}
          className="mb-10 text-blue-600 hover:underline cursor-pointer text-left"
        >
          Manage billing information and documents{" "}
          <i className="fa-solid fa-chevron-right ml-5"></i>
        </Link>
        <div className="container flex flex-col justify-center lg:grid lg:grid-cols-2 lg:gap-8">
          <div
            className={`relative p-8 border border-gray-200 rounded-2xl shadow-sm flex flex-col justify-between mt-8 lg:mt-0 ${
              user?.pricingPlan === PricingPlans.FREE ? "bg-gray-100" : ""
            }`}
          >
            <div className="flex-1">
              <h3 className="text-xl font-semibold">Free</h3>
              <p className="mt-4 flex items-baseline ">
                <span className="text-5xl font-extrabold tracking-tight">
                  $0
                </span>
                <span className="ml-1 text-xl font-semibold">/month</span>
              </p>
              <p className="mt-6 ">You want to discover</p>
              <ul role="list" className="mt-6 space-y-6">
                <li className="flex">
                  <i className="fa-solid fa-check text-xl text-blue-500" />
                  <span className="ml-3 ">No email validation</span>
                </li>
                <li className="flex">
                  <i className="fa-solid fa-check text-xl text-blue-500" />
                  <span className="ml-3 ">30 MB file limit</span>
                </li>
                <li className="flex">
                  <i className="fa-solid fa-check text-xl text-blue-500" />
                  <span className="ml-3 ">
                    Submissions auto-delete 14 days after due date
                  </span>
                </li>
              </ul>
            </div>
            <button
              onClick={handleConfirmCancelSubscription}
              className={`bg-blue-500 text-white hover:bg-blue-600 mt-8 block w-full py-3 px-6 border border-transparent rounded-md text-center font-medium ${
                user?.pricingPlan === PricingPlans.FREE
                  ? "bg-gray-300 hover:bg-gray-400 cursor-not-allowed"
                  : ""
              }`}
              disabled={user?.pricingPlan === PricingPlans.FREE}
            >
              {user?.pricingPlan === PricingPlans.FREE
                ? "Current Plan"
                : "Downgrade"}
            </button>
          </div>
          {subscriptionPlans.map((item: any, index: any) => (
            <div
              key={index.toString()}
              className={`relative p-8 border border-gray-200 rounded-2xl shadow-sm flex flex-col justify-between mt-8 lg:mt-0 ${
                user?.pricingPlan === item?.product?.name ? "bg-gray-100" : ""
              }`}
            >
              <div className="flex-1">
                <h3 className="text-xl font-semibold capitalize">
                  {item?.product?.name.charAt(0).toUpperCase() +
                    item?.product?.name.slice(1).toLowerCase()}
                </h3>
                <p className="mt-4 flex items-baseline ">
                  <span className="text-5xl font-extrabold tracking-tight">
                    ${item?.price?.unit_amount / 100}
                  </span>
                  <span className="ml-1 text-xl font-semibold">/month</span>
                </p>
                <p className="mt-6 ">You want Pro Superpowers</p>
                <ul role="list" className="mt-6 space-y-6">
                  <li className="flex">
                    <i className="fa-solid fa-check text-xl text-blue-500" />
                    <span className="ml-3 ">100 MB file limit</span>
                  </li>
                  <li className="flex">
                    <i className="fa-solid fa-check text-xl text-blue-500" />
                    <span className="ml-3 ">Submission dashboard access</span>
                  </li>
                  <li className="flex">
                    <i className="fa-solid fa-check text-xl text-blue-500" />
                    <span className="ml-3 ">
                      Submissions saved until deleted
                    </span>
                  </li>
                  <li className="flex">
                    <i className="fa-solid fa-check text-xl text-blue-500" />
                    <span className="ml-3 ">10 GB file storage</span>
                  </li>
                  <li className="flex">
                    <i className="fa-solid fa-check text-xl text-blue-500" />
                    <span className="ml-3 ">
                      Company logo in review invitation email
                    </span>
                  </li>
                  <li className="flex">
                    <i className="fa-solid fa-check text-xl text-blue-500" />
                    <span className="ml-3 ">Optional due date</span>
                  </li>
                </ul>
              </div>
              <button
                onClick={() => handleCheckoutSession(item?.price?.id)}
                className={`bg-blue-500 text-white hover:bg-blue-600 mt-8 block w-full py-3 px-6 border border-transparent rounded-md text-center font-medium ${
                  user?.pricingPlan === item?.product?.name
                    ? "bg-gray-300 hover:bg-gray-400 cursor-not-allowed"
                    : ""
                }`}
                disabled={user?.pricingPlan === item?.product?.name}
              >
                {user?.pricingPlan === item?.product?.name
                  ? "Current Plan"
                  : "Upgrade"}
              </button>
            </div>
          ))}
        </div>
      </div>
      <div className="h-32"></div>
      {isLoading ? <LoadingSpinner /> : null}
      <ToastContainer />
      <PopupConfirmationComponent
        popUpDisplay={isConfirmationOpen}
        handleClose={() => setConfirmationOpen(false)}
        handleOpen={() => setConfirmationOpen(true)}
        popUpTitle={"Last warning"}
        popUpContent={
          "Are you sure you want to cancel your subscription? By canceling your subscription, you will lose access to our Pro features."
        }
        handleConfirm={() => handleCancelSubscription()}
      />
    </React.Fragment>
  );
}
