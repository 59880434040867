import axios from "axios";
import { ENDPOINT } from "./api-endpoints.routes";

// Verify OTP API call
export const generateOtpForNonRegisterUser = async (email: string) => {
  try {
    const response = await axios.post(
      `${ENDPOINT.OTP_FOR_NON_REGISTER_USERS.GENERATE}`,
      {
        email,
      }
    );
    return { success: true, data: response.data };
  } catch (error: any) {
    return { success: false, error: error.response.data.message };
  }
};

// Verify OTP API call
export const verifyOtpForNonRegisterUser = async (email: string, otp: number) => {
  try {
    const response = await axios.put(
      `${ENDPOINT.OTP_FOR_NON_REGISTER_USERS.VERIFY}`,
      {
        email,
        otp,
      }
    );
    return { success: true, data: response.data };
  } catch (error: any) {
    return { success: false, error: error.response.data.message };
  }
};
