import {
  BrowserRouter,
  Navigate,
  Route,
  RouteProps,
  Routes,
  useNavigate,
} from "react-router-dom";
import { VIEWS } from "./app/routes/routes";
import SignUp from "./app/pages/auth/sign-up";
import SignIn from "./app/pages/auth/sign-in";
import ForgotPassword from "./app/pages/auth/forgot-password";
import ResetPassword from "./app/pages/auth/reset-password";
import PrivacyPolicy from "./app/pages/public/privacy-policy";
import TermsConditions from "./app/pages/public/terms-conditions";
import UserProtectedRoutes from "./app/middleware/user-protected-routes.middleware";
import DashboardLayout from "./app/pages/protected/dashboard-layout";
import Dashboard from "./app/pages/protected/dashboard";
import MyDocuments from "./app/pages/protected/my-documents";
import Settings from "./app/pages/protected/settings";
import Subscriptions from "./app/pages/protected/subscriptions";
import PaymentSuccess from "./app/pages/protected/payment-success";
import PaymentCancel from "./app/pages/protected/payment-cancel";
import Home from "./app/pages/public/home";
import About from "./app/pages/public/about";
import Pricing from "./app/pages/public/pricing";
import VerifyOtp from "./app/pages/auth/verify-otp";
import CookieBanner from "./app/components/cookie-banner.component";
import NotFound from "./app/components/not-found.component";
import Help from "./app/pages/public/help";
import UserPrivacyPolicy from "./app/pages/protected/privacy-policy";
import UserTermsConditions from "./app/pages/protected/terms-conditions";
import UserHelp from "./app/pages/protected/help";

function App() {
  return (
    <>
      {/* <CookieBanner /> */}
      <BrowserRouter>
        <Routes>
          {/* Public routes*/}
          <Route path={VIEWS.HOME} index element={<Home />} />
          <Route path={VIEWS.HOME_WITH_PREFIX} index element={<Home />} />
          <Route path={VIEWS.SIGN_UP} element={<SignUp />} />
          <Route path={VIEWS.SIGN_IN} element={<SignIn />} />
          <Route path={VIEWS.VERIFY_OTP} element={<VerifyOtp />} />
          <Route path={VIEWS.FORGOT_PASSWORD} element={<ForgotPassword />} />
          <Route path={VIEWS.RESET_PASSWORD} element={<ResetPassword />} />
          <Route path={VIEWS.ABOUT} element={<About />} />
          <Route path={VIEWS.PRICING} element={<Pricing />} />
          <Route path={VIEWS.PRIVACY_POLICY} element={<PrivacyPolicy />} />
          <Route path={VIEWS.TERMS_CONDITIONS} element={<TermsConditions />} />
          <Route path={VIEWS.HELP} element={<Help />} />
          {/* Protected routes*/}
          <Route path="/user" element={<UserProtectedRoutes />}>
            <Route
              path={VIEWS.USER_DASHBOARD_LAYOUT}
              element={<DashboardLayout />}
            >
              {/* When the user trying navigating  to the -> /user (index route of this nested routes) it will redirecting to the -> /user/dashboard*/}
              <Route index element={<Navigate to={VIEWS.USER_DASHBOARD} />} />
              {/* user protected other routes*/}
              <Route element={<Navigate to={VIEWS.USER_DASHBOARD} />} />
              <Route path={VIEWS.USER_DASHBOARD} element={<Dashboard />} />
              <Route path={VIEWS.USER_DOCUMENTS} element={<MyDocuments />} />
              <Route path={VIEWS.USER_SETTINGS} element={<Settings />} />
              <Route
                path={VIEWS.USER_SUBSCRIPTIONS}
                element={<Subscriptions />}
              />
              <Route
                path={VIEWS.USER_PAYMENT_SUCCESS}
                element={<PaymentSuccess />}
              />
              <Route
                path={VIEWS.USER_PAYMENT_CANCEL}
                element={<PaymentCancel />}
              />
              <Route
                path={VIEWS.USER_PRIVACY_POLICY}
                element={<UserPrivacyPolicy />}
              />
              <Route
                path={VIEWS.USER_TERMS_CONDITIONS}
                element={<UserTermsConditions />}
              />
              <Route path={VIEWS.USER_HELP} element={<UserHelp />} />
            </Route>
          </Route>
          <Route path="*" Component={NotFound} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
