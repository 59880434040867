import React, { useState } from "react";
import InputField from "../../input-field.component";
import Button from "../../button.component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingSpinner from "../../loading-spinner.component";
import { changePassword } from "../../../services/auth-api.service";
import useToken from "../../../hooks/useToken";

export default function ChangePwForm(props: {}) {
  const { token, setToken, clearToken } = useToken();
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleChangePassword = async () => {
    if (currentPassword === "") {
      toast.error("Please enter current password");
    } else if (newPassword === "") {
      toast.error("Please enter new password");
    } else if (confirmPassword !== newPassword) {
      toast.error("Confirm password is not match");
    } else {
      try {
        setIsLoading(true);
        const response = await changePassword(
          token || "",
          currentPassword,
          newPassword,
          confirmPassword
        );
        if (response.success) {
          console.log("RESPONSE", response?.data);
          setCurrentPassword("");
          setNewPassword("");
          setConfirmPassword("");
          setIsLoading(false);
          toast.success(response.data?.message);
        } else {
          setIsLoading(false);
          toast.error(response.error);
        }
      } catch (error: any) {
        setIsLoading(false);
      }
    }
  };

  return (
    <React.Fragment>
      <div className="w-full bg-blue-50 p-5 lg:p-10 rounded-xl my-10">
        <h1 className="text-xl font-semibold mt-5 mb-10 text-slate-600">
          Change Password
        </h1>
        <span className="w-full mt-5 md:mr-5">
          <InputField
            icon="fa-solid fa-lock"
            type="password"
            label="Current password *"
            placeholder="Enter your current password"
            value={currentPassword}
            onChange={setCurrentPassword}
          />
        </span>
        <div className="flex flex-col md:flex-row">
          <span className="w-full md:mr-5">
            <InputField
              icon="fa-solid fa-lock"
              type="password"
              label="New password *"
              placeholder="Enter your new password"
              value={newPassword}
              onChange={setNewPassword}
            />
          </span>
          <span className="w-full">
            <InputField
              icon="fa-solid fa-lock"
              type="password"
              label="Confirm password *"
              placeholder="Re-enter your password"
              value={confirmPassword}
              onChange={setConfirmPassword}
            />
          </span>
        </div>
        <div className="w-full md:w-1/2 lg:w-2/5 mt-5">
          <Button name={"Save Changes"} handleAction={handleChangePassword} />
        </div>
      </div>
      {isLoading ? <LoadingSpinner /> : null}
      <ToastContainer />
    </React.Fragment>
  );
}
