import React, { useEffect, useState } from "react";
import LoadingSpinner from "../../components/loading-spinner.component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  deleteDocument,
  getDocumentsByUser,
} from "../../services/document-api.service";
import useToken from "../../hooks/useToken";
import { useAuth } from "../../common/providers/user.provider";
import { formatTimestamp } from "../../common/utils/date-format";
import PopupConfirmationComponent from "../../components/popup-confirmation.component";
import { Link, useNavigate } from "react-router-dom";
import { VIEWS } from "../../routes/routes";
import { useScrollToTop } from "../../common/hooks/use-scroll-to-top";
import PopupComponent from "../../components/popup.component";
import { Paginator } from "primereact/paginator";
import { PricingPlans } from "../../common/enums/pricing-plan.enum";

export default function MyDocuments() {
  useScrollToTop();
  const navigate = useNavigate();
  const { token, setToken, clearToken } = useToken();
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);
  const [documentId, setDocumentId] = useState("");
  const [showNoContentMessage, setShowNoContentMessage] = useState(false);
  const [isDocDetailedOpen, setIsDocDetailedOpen] = useState(false);
  const [docDetails, setDocDetails] = useState({
    id: "",
    senderEmail: "",
    receiverEmail: "",
    dueDate: "",
    reminder: "",
    message: "",
    file: "",
    isSendFeedback: false,
    isApproved: false,
    createdAt: "",
    fileName: "",
  });

  // Handling the pagination
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(5);
  const [total, setTotal] = useState<number>(0);

  //PrimeReact paginator handler
  const onPageChange = (event: any) => {
    const newPage = event.page + 1;
    const newPageSize = event.rows;
    setPageSize(newPageSize);
    setCurrentPage(newPage);
  };

  useEffect(() => {
    const storedToken = localStorage.getItem("accessToken");
    const storedUser = JSON.parse(localStorage.getItem("user") || "");
    if (storedToken && (user || storedUser)) {
      const userId = user ? user?.id : storedUser?.id;
      handleGetDocuments(storedToken, userId);
    }
    const timeoutId = setTimeout(
      () => {
        setShowNoContentMessage(true);
      },
      documents?.length === 0 ? 0 : 2000
    );
    return () => clearTimeout(timeoutId);
  }, [currentPage, pageSize]);

  const handleGetDocuments = async (token: any, userId: string) => {
    try {
      setIsLoading(true);
      // Calling to the API service
      const response = await getDocumentsByUser(
        token,
        userId,
        currentPage,
        pageSize
      );
      // Handling the response
      if (response.success) {
        setDocuments(response?.data?.data?.items);
        setTotal(response?.data?.data?.pageInfo?.totalDocuments);
      } else {
        toast.error(response.error);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error during form action:", error);
      setIsLoading(false);
    }
  };

  const handleConfirmDeleteDocument = async (documentId: string) => {
    setDocumentId(documentId);
    setConfirmationOpen(true);
  };

  const handleDeleteDocument = async () => {
    try {
      setConfirmationOpen(false);
      setIsLoading(true);
      // Calling to the API service
      const response = await deleteDocument(token, documentId);
      // Handling the response
      if (response.success) {
        handleGetDocuments(token, user?.id || "");
        toast.success(response?.data?.message);
      } else {
        toast.error(response.error);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error during form action:", error);
      setIsLoading(false);
    }
  };

  const handleSetDocumentDetails = async (data: any) => {
    console.log(data);
    setIsDocDetailedOpen(true);
    setDocDetails({
      id: data?._id,
      senderEmail: data?.senderEmail,
      receiverEmail: data?.receiverEmail,
      dueDate: data?.dueDate,
      reminder: data?.reminder,
      message: data?.message,
      file: data?.file,
      isSendFeedback: data?.isSendFeedback,
      isApproved: data?.isApproved,
      createdAt: data?.createdAt,
      fileName: data?.fileName,
    });
  };

  return (
    <React.Fragment>
      {user?.pricingPlan === PricingPlans.FREE ? (
        <div className="fixed inset-0 z-20 flex items-center justify-center backdrop-blur-md lg:ml-72 mt-20">
          <div className="bg-gray-800 bg-opacity-75 fixed inset-0 transition-opacity flex justify-center items-center">
            <div className="relative p-20 mx-auto min-w-96 max-w-md bg-slate-50 rounded-lg flex flex-col justify-center items-center">
              <h1 className="text-sm text-center font-semibold mb-4">
                To view the documents upgrade to Pro
              </h1>
              <Link
                to={VIEWS.USER_SUBSCRIPTIONS}
                className="py-5 px-12 bg-blue-600 hover:bg-blue-700 text-white font-semibold rounded-lg text-sm mt-5"
              >
                Upgrade to Pro <i className="fa-solid fa-crown ml-2"></i>
              </Link>
            </div>
          </div>
        </div>
      ) : null}
      <h1 className="text-5xl text-slate-700 font-bold">My Documents</h1>
      {documents.length > 0 ? (
        <div className="relative overflow-x-auto shadow-xl sm:rounded-lg mt-16">
          <table className="w-full text-xs lg:text-sm text-left">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Receiver
                </th>
                <th scope="col" className="px-6 py-3">
                  Created Date
                </th>
                <th scope="col" className="px-6 py-3">
                  Due Date
                </th>
                <th scope="col" className="px-6 py-3">
                  Approve
                </th>
                <th scope="col" className="px-6 py-3">
                  Feedback
                </th>
                <th scope="col" className="px-6 py-3">
                  Options
                </th>
              </tr>
            </thead>
            <tbody>
              {documents
                ?.map((item: any) => (
                  <tr
                    className="bg-white border-b hover:bg-gray-50"
                    key={item._id}
                  >
                    <td className="px-6 py-4">{item?.receiverEmail}</td>
                    <td className="px-6 py-4">
                      {formatTimestamp(item?.createdAt)}
                    </td>
                    <td className="px-6 py-4">
                      {formatTimestamp(item?.dueDate)}
                    </td>
                    <td className="px-6 py-4">
                      <p
                        className={`${
                          item?.isApproved
                            ? "bg-green-400 text-white"
                            : "bg-slate-200 text-slate-500"
                        } text-xs font-normal rounded-lg w-fit py-1 px-2 flex items-center`}
                      >
                        {item?.isApproved ? (
                          <>
                            <span>Approved</span>
                            <i className="fa-regular fa-circle-check ml-1" />
                          </>
                        ) : (
                          <span>Pending</span>
                        )}
                      </p>
                    </td>
                    <td className="px-6 py-4">
                      <p
                        className={`${
                          item?.isSendFeedback
                            ? "bg-green-400 text-white"
                            : "bg-slate-200 text-slate-500"
                        } text-xs font-normal rounded-lg w-fit py-1 px-2 flex items-center`}
                      >
                        {item?.isSendFeedback ? (
                          <>
                            <span>Sent</span>
                            <i className="fa-regular fa-circle-check ml-1" />
                          </>
                        ) : (
                          <span>Pending</span>
                        )}
                      </p>
                    </td>
                    <td className="px-6 py-4 text-right flex flex-row justify-start items-center">
                      <button
                        className="rounded-xl bg-blue-600 hover:bg-blue-700 p-2 text-white font-bold w-10 h-10 mr-1"
                        onClick={() => {
                          const url = `${process.env.REACT_APP_DOCUMENT_APP}${item?._id}?authToken=${token}`;
                          window.open(url, "_blank");
                        }}
                      >
                        <i className="fa-regular fa-eye"></i>
                      </button>
                      <button
                        className="rounded-xl bg-indigo-400 hover:bg-indigo-500 p-2 text-white font-bold w-10 h-10 mx-1"
                        onClick={() => handleSetDocumentDetails(item)}
                      >
                        <i className="fa-solid fa-circle-info"></i>
                      </button>
                      <button
                        className="rounded-xl bg-rose-400 hover:bg-rose-500 p-2 text-white font-bold w-10 h-10 mx-1"
                        onClick={() => handleConfirmDeleteDocument(item?._id)}
                      >
                        <i className="fa-regular fa-trash-can"></i>
                      </button>
                    </td>
                  </tr>
                ))
                .reverse()}
            </tbody>
          </table>
          <div className="flex justify-center items-center w-full overflow-x-auto">
            <Paginator
              first={(currentPage - 1) * pageSize}
              rows={pageSize}
              totalRecords={total || 0}
              onPageChange={onPageChange}
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
              prevPageLinkIcon={<p>Previous</p>}
              nextPageLinkIcon={<p>Next</p>}
              firstPageLinkIcon={null}
              lastPageLinkIcon={null}
            />
          </div>
        </div>
      ) : showNoContentMessage ? (
        <div className="w-full flex flex-col justify-center items-center mt-24">
          <span className="rounded-full h-64 w-64 bg-slate-50 flex flex-col justify-center items-center text-slate-300">
            <i className="fa-regular fa-face-frown text-8xl"></i>
            <h1 className="text-slate-300 mt-5 text-xs">
              No documents available
            </h1>
          </span>
          <Link
            to={VIEWS.USER_DASHBOARD}
            className="w-fit rounded-lg bg-blue-500 hover:bg-blue-600 py-3 px-4 text-white mt-12"
          >
            Submit your first document{" "}
            <i className="fa-solid fa-chevron-right ml-4" />
          </Link>
        </div>
      ) : null}
      <div className="h-32"></div>
      {isLoading ? <LoadingSpinner /> : null}
      <ToastContainer />
      <PopupConfirmationComponent
        popUpDisplay={isConfirmationOpen}
        handleClose={() => setConfirmationOpen(false)}
        handleOpen={() => setConfirmationOpen(true)}
        popUpTitle={"Last warning"}
        popUpContent={"Are you sure want to delete this document?"}
        handleConfirm={() => handleDeleteDocument()}
      />
      <PopupComponent
        popUpDisplay={isDocDetailedOpen}
        handleClose={() => setIsDocDetailedOpen(false)}
        handleOpen={() => setIsDocDetailedOpen(true)}
        popUpTitle={"Document Details"}
      >
        <div className="p-4 text-sm">
          <div className="mb-4 flex flex-row items-center justify-start">
            <span className="font-semibold text-gray-500">
              <i className="fa-regular fa-envelope mr-1" /> Sender Email:
            </span>
            <p className="mx-2">{docDetails?.senderEmail}</p>
          </div>
          <div className="mb-4 flex flex-row items-center justify-start">
            <span className="font-semibold text-gray-500">
              <i className="fa-regular fa-envelope mr-1" /> Receiver Email:
            </span>
            <p className="mx-2">{docDetails?.receiverEmail}</p>
          </div>
          <div className="mb-4 flex flex-row items-center justify-start">
            <span className="font-semibold text-gray-500">
              <i className="fa-regular fa-calendar mr-1" /> Due Date:
            </span>
            <p className="mx-2">{formatTimestamp(docDetails?.dueDate)}</p>
          </div>
          <div className="mb-4 flex flex-row items-center justify-start">
            <span className="font-semibold text-gray-500">
              <i className="fa-regular fa-clock mr-1" /> Reminder:
            </span>
            <p className="mx-2">
              {docDetails?.reminder === "" ? "N/A" : docDetails?.reminder}
            </p>
          </div>
          <div className="mb-4">
            <span className="font-semibold text-gray-500">
              <i className="fa-regular fa-message mr-1" /> Message:
            </span>
            <p className="text-xs bg-slate-100 rounded-lg w-full h-fit px-5 py-2 mt-2">
              {docDetails?.message}
            </p>
          </div>
          <div className="mb-4 flex flex-row items-center justify-start">
            <span className="font-semibold text-gray-500">
              <i className="fa-solid fa-link mr-1" /> File:
            </span>
            <p className="mx-2">{docDetails?.fileName}</p>
          </div>
          <div className="mb-4 flex flex-row items-center justify-start">
            <span className="font-semibold text-gray-500">
              <i className="fa-regular fa-comments mr-1" /> Approved Status:
            </span>
            <p
              className={`${
                docDetails?.isApproved
                  ? "bg-green-400 text-white"
                  : "bg-slate-200 text-slate-500"
              } text-xs text-semibold rounded-lg w-fit py-1 px-2 mx-2`}
            >
              {docDetails?.isApproved ? "Approved" : "Pending"}
              {docDetails?.isApproved ? (
                <i className="fa-regular fa-circle-check ml-1" />
              ) : null}
            </p>
          </div>
          <div className="mb-4 flex flex-row items-center justify-start">
            <span className="font-semibold text-gray-500">
              <i className="fa-regular fa-comments mr-1" /> Send Feedback:
            </span>
            <p
              className={`${
                docDetails?.isSendFeedback
                  ? "bg-green-400 text-white"
                  : "bg-slate-200 text-slate-500"
              } text-xs text-semibold rounded-lg w-fit py-1 px-2 mx-2`}
            >
              {docDetails?.isSendFeedback ? "Sent" : "Pending"}
              {docDetails?.isSendFeedback ? (
                <i className="fa-regular fa-circle-check ml-1" />
              ) : null}
            </p>
          </div>
          <div className="mb-4 flex flex-row items-center justify-start">
            <span className="font-semibold text-gray-500">
              <i className="fa-regular fa-file-lines mr-1" /> Created At:
            </span>
            <p className="mx-2">{formatTimestamp(docDetails?.createdAt)}</p>
          </div>
        </div>
      </PopupComponent>
    </React.Fragment>
  );
}
