import React, { useEffect, useState } from "react";
import { Link, useNavigate, redirect, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingSpinner from "../../components/loading-spinner.component";
import { useScrollToTop } from "../../common/hooks/use-scroll-to-top";
import { VIEWS } from "../../routes/routes";
import TopNavbar from "../../components/top-navbar.component";
import Footer from "../../components/footer.component";
import coverImage from "../../common/assets/images/about.png";
import secondaryImage from "../../common/assets/images/sign_in.png";
import useToken from "../../hooks/useToken";

export default function About() {
  useScrollToTop();
  const navigate = useNavigate();
  const { token, setToken, clearToken } = useToken();
  const [isLoading, setIsLoading] = useState(false);

  //If the token is available public pages are unavailable
  useEffect(() => {
    if (token) {
      navigate(VIEWS.USER_DASHBOARD);
    }
  }, [token]);

  return (
    <React.Fragment>
      <div className="flex flex-col min-h-screen px-5 md:px-20 lg:px-20 xl:px-32">
        <TopNavbar
          btnName={"Sign In"}
          btnAction={() => navigate(VIEWS.SIGN_IN)}
        />
        {/* SECTION ONE */}
        <div className="w-full flex flex-col lg:flex-row justify-center items-center mt-20 min-h-screen h-full">
          {/* Left side container */}
          <div className="w-full lg:w-full min-h-screen lg:min-h-0 lg:flex justify-start items-start flex-col lg:p-0 p-5">
            <h1 className="text-left text-6xl lg:text-8xl font-bold text-blue-950 leading-snug mt-8 lg:mt-20">
              About
            </h1>
            <h1 className="font-bold text-slate-600 text-xl leading-10 text-left mt-10">
              Welcome to Revizite, where simplicity transforms the way you
              collaborate on document reviews.
            </h1>
            <button
              onClick={() => navigate(VIEWS.PRICING)}
              type="button"
              className="flex flex-row justify-between items-center bg-primary-light hover:bg-violet-500 text-white rounded-xl text-lg px-5 py-4 text-center font-semibold w-fit mt-8"
            >
              See pricing plans{" "}
              <i className="fa-solid fa-angle-right ml-10"></i>
            </button>
            <div>
              <h1 className="font-bold text-slate-600 text-xl leading-10 text-left mt-10">
                Our Vision
              </h1>
              <p className="font-normal text-slate-600 text-md leading-10 text-left">
                At Revizite, we have a clear vision — to make it effortless for
                individuals, regardless of technical expertise, to provide swift
                and frustration-free feedback on documents. We believe in
                simplifying the collaboration process, reducing the need for
                extensive communication, and accelerating feedback and approval
                cycles for businesses.
              </p>
            </div>
            <div>
              <h1 className="font-bold text-slate-600 text-xl leading-10 text-left mt-10">
                Who We Are
              </h1>
              <p className="font-normal text-slate-600 text-md leading-10 text-left">
                Our journey began with a commitment to simplicity and a passion
                for helping businesses obtain feedback and approvals faster.
                Whether you're a marketing professional seeking quick approvals
                or a creative mind craving a straightforward feedback process,
                Revizite is your solution.
              </p>
            </div>
          </div>
          {/* Right side container */}
          <div className="w-full lg:w-full h-auto flex justify-center items-start mt-8 lg:mt-0">
            <img src={coverImage} alt="" className="w-full lg:w-2/3 h-auto" />
          </div>
        </div>

        {/* SECTION TWO */}
        <div className="w-full flex flex-col-reverse lg:flex-row justify-center items-center h-full">
          {/* Left side container */}
          <div className="w-full lg:w-full h-auto flex justify-center items-start mt-8 lg:mt-0">
            <img src={secondaryImage} alt="" className="w-full lg:w-2/3 h-auto" />
          </div>
          {/* Right side container */}
          <div className="w-full lg:w-full min-h-screen lg:min-h-0 lg:flex justify-start items-start flex-col lg:p-0 p-5">
            <div>
              <h1 className="font-bold text-slate-600 text-xl leading-10 text-left mt-10">
                Key Features
              </h1>
              <p className="font-normal text-slate-600 text-md leading-10 text-left">
                <ul className="list-disc">
                  <li>
                    Simplicity Redefined: Revizite is designed to be
                    user-friendly, ensuring that providing feedback is a
                    straightforward and enjoyable experience for everyone.
                  </li>
                  <li>
                    Fast and Efficient: Our platform is built to accelerate
                    feedback cycles, reducing the need for prolonged
                    communication and enabling swift decision-making.
                  </li>
                  <li>
                    Tools for Everyone: We provide simple yet powerful tools
                    accessible to users. No complex interfaces or extensive
                    training needed.
                  </li>
                </ul>
              </p>
            </div>
            <div>
              <h1 className="font-bold text-slate-600 text-xl leading-10 text-left mt-10">
                Our Commitment
              </h1>
              <p className="font-normal text-slate-600 text-md leading-10 text-left">
                Our commitment is unwavering — to continue refining and
                enhancing a platform that empowers businesses to gather feedback
                and approvals with ease. We prioritise simplicity, understanding
                that your time is valuable and should be spent on meaningful
                tasks, not navigating complicated collaboration tools with steep
                learning curves.
              </p>
            </div>
            <div>
              <h1 className="font-bold text-slate-600 text-xl leading-10 text-left mt-10">
                Join Us
              </h1>
              <p className="font-normal text-slate-600 text-md leading-10 text-left">
                Embark on a journey where providing and receiving feedback
                becomes a seamless part of your workflow. Join Revizite today
                and experience the difference simplicity can make in your
                document collaboration process.
                <br />
                <br />
                Ready to Revolutionise the Way You Provide or Get Feedback on
                Documents? Sign up for Revizite today and discover the joy of
                efficient collaboration with straightforward tools.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="h-32"></div>
      <Footer />
      {isLoading ? <LoadingSpinner /> : null}
      <ToastContainer />
    </React.Fragment>
  );
}
