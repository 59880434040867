export enum ReminderValues {
  TwoDaysAfter = "2 days after",
  OneDayAfter = "1 day after",
  OnDueDate = "On due date",
  // OneHourBefore = "1 hour before",
  // ThreeHoursBefore = "3 hours before",
  OneDayBefore = "1 day before",
  TwoDaysBefore = "2 days before",
  ThreeDaysBefore = "3 days before",
  NoReminder = "No reminder",
}
