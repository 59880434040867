import React, { useEffect, useState } from "react";
import { Link, useNavigate, redirect, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingSpinner from "../../components/loading-spinner.component";
import { useScrollToTop } from "../../common/hooks/use-scroll-to-top";
import { VIEWS } from "../../routes/routes";
import TopNavbar from "../../components/top-navbar.component";
import Footer from "../../components/footer.component";
import useToken from "../../hooks/useToken";

export default function Help() {
  useScrollToTop();
  const navigate = useNavigate();
  const { token, setToken, clearToken } = useToken();
  const [isLoading, setIsLoading] = useState(false);

  //If the token is available public pages are unavailable
  useEffect(() => {
    if (token) {
      navigate(VIEWS.USER_HELP);
    }
  }, [token]);

  return (
    <React.Fragment>
      <div className="flex flex-col min-h-screen">
        <TopNavbar
          btnName={"Sign In"}
          btnAction={() => navigate(VIEWS.SIGN_IN)}
        />
        <div className="w-full min-h-screen flex flex-col justify-center items-center p-8 md:p-16 lg:p-32">
          <h1 className="text-center text-4xl lg:text-6xl font-bold text-blue-950 leading-snug mt-24 lg:mt-10">
            Need Assistance?
          </h1>
          <h2 className="text-center text-2xl lg:text-4xl font-semibold text-blue-950 leading-normal mt-20">
            Contact Our Support Team
          </h2>
          <p className="text-center text-lg text-gray-400 leading-snug mt-6">
            If you have any questions, concerns, or need assistance with
            Revizite, our support team is here to help.
          </p>
          <p className="text-center text-lg text-gray-400 leading-snug mt-2">
            Feel free to reach out to us via email at{" "}
            <a
              href="mailto:team@revizite.com"
              className="text-blue-600 hover:underline"
            >
              team@revizite.com
            </a>
          </p>
        </div>
        <Footer />
      </div>
      {isLoading ? <LoadingSpinner /> : null}
      <ToastContainer />
    </React.Fragment>
  );
}
