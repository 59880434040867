import React, { useEffect, useState } from "react";
import { Link, useNavigate, redirect, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingSpinner from "../../components/loading-spinner.component";
import { useScrollToTop } from "../../common/hooks/use-scroll-to-top";
import { VIEWS } from "../../routes/routes";
import TopNavbar from "../../components/top-navbar.component";
import Footer from "../../components/footer.component";
import coverImage from "../../common/assets/images/about.png";
import useToken from "../../hooks/useToken";
import { getAllSubscriptionPlans } from "../../services/subcriptions-api.service";

export default function Pricing() {
  useScrollToTop();
  const navigate = useNavigate();
  const { token, setToken, clearToken } = useToken();
  const [isLoading, setIsLoading] = useState(false);
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);

  //If the token is available public pages are unavailable
  useEffect(() => {
    if (token) {
      navigate(VIEWS.USER_DASHBOARD);
    }
    handleGetAllSubscriptionPlans();
  }, [token]);

  const handleGetAllSubscriptionPlans = async () => {
    try {
      setIsLoading(true);
      // Calling to the API service
      const response = await getAllSubscriptionPlans();
      // Handling the response
      if (response.success) {
        setSubscriptionPlans(response?.data?.data);
      } else {
        toast.error(response.error);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error during form action:", error);
      setIsLoading(false);
    }
  };

  return (
    <React.Fragment>
      <div className="flex flex-col min-h-screen">
        <TopNavbar
          btnName={"Sign In"}
          btnAction={() => navigate(VIEWS.SIGN_IN)}
        />
        <div className="w-full flex flex-col justify-center items-center py-20 mt-20 px-5 md:px-20 lg:px-20 xl:px-32">
          <h1 className="text-6xl lg:text-8xl font-bold text-blue-950 leading-snug">
            Pricing
          </h1>
          <p className="font-normal text-slate-600 text-md text-center mt-5">
            Get started on our free plan and upgrade when you are ready.
          </p>
          <div className="w-fit flex flex-col justify-center items-center my-20">
            <div className="container flex flex-col lg:grid lg:grid-cols-2 lg:gap-8">
              <div className="relative p-8 border border-gray-200 rounded-2xl shadow-sm flex flex-col justify-between">
                <div className="flex-1">
                  <h3 className="text-xl font-semibold capitalize">Free</h3>
                  <p className="mt-4 flex items-baseline ">
                    <span className="text-5xl font-extrabold tracking-tight">
                      $0
                    </span>
                    <span className="ml-1 text-xl font-semibold">/month</span>
                  </p>
                  <p className="mt-6 ">You want to discover</p>
                  <ul role="list" className="mt-6 space-y-6">
                    <li className="flex">
                      <i className="fa-solid fa-check text-xl text-blue-500" />
                      <span className="ml-3 ">No email validation</span>
                    </li>
                    <li className="flex">
                      <i className="fa-solid fa-check text-xl text-blue-500" />
                      <span className="ml-3 ">30 MB file limit</span>
                    </li>
                    <li className="flex">
                      <i className="fa-solid fa-check text-xl text-blue-500" />
                      <span className="ml-3 ">
                        Submissions auto-delete 14 days after due date
                      </span>
                    </li>
                  </ul>
                </div>
                <Link
                  className="bg-blue-50 text-blue-700 hover:bg-blue-100 mt-8 block w-full py-3 px-6 border border-transparent rounded-md text-center font-medium"
                  to={VIEWS.SIGN_UP}
                >
                  Sign up for free
                </Link>
              </div>
              {subscriptionPlans.map((item: any, index: any) => (
                <div
                  key={index.toString()}
                  className="relative p-8 border border-gray-200 rounded-2xl shadow-sm flex flex-col justify-between mt-8 lg:mt-0"
                >
                  <div className="flex-1">
                    <h3 className="text-xl font-semibold">
                      {item?.product?.name.charAt(0).toUpperCase() +
                        item?.product?.name.slice(1).toLowerCase()}
                    </h3>
                    {/* <p className="absolute top-0 py-1.5 px-4 bg-emerald-500 text-white rounded-full text-xs font-semibold uppercase tracking-wide  transform -translate-y-1/2">
                    Most popular
                  </p> */}
                    <p className="mt-4 flex items-baseline ">
                      <span className="text-5xl font-extrabold tracking-tight">
                        ${item?.price?.unit_amount / 100}
                      </span>
                      <span className="ml-1 text-xl font-semibold">/month</span>
                    </p>
                    <p className="mt-6 ">You want Pro Superpowers</p>
                    <ul role="list" className="mt-6 space-y-6">
                      <li className="flex">
                        <i className="fa-solid fa-check text-xl text-blue-500" />
                        <span className="ml-3 ">100 MB file limit</span>
                      </li>
                      <li className="flex">
                        <i className="fa-solid fa-check text-xl text-blue-500" />
                        <span className="ml-3 ">
                          Submission dashboard access
                        </span>
                      </li>
                      <li className="flex">
                        <i className="fa-solid fa-check text-xl text-blue-500" />
                        <span className="ml-3 ">
                          Submissions saved until deleted
                        </span>
                      </li>
                      <li className="flex">
                        <i className="fa-solid fa-check text-xl text-blue-500" />
                        <span className="ml-3 ">10 GB file storage</span>
                      </li>
                      <li className="flex">
                        <i className="fa-solid fa-check text-xl text-blue-500" />
                        <span className="ml-3 ">
                          Company logo in review invitation email
                        </span>
                      </li>
                      <li className="flex">
                        <i className="fa-solid fa-check text-xl text-blue-500" />
                        <span className="ml-3 ">Optional due date</span>
                      </li>
                    </ul>
                  </div>
                  <Link
                    className="bg-blue-500 text-white hover:bg-blue-600 mt-8 block w-full py-3 px-6 border border-transparent rounded-md text-center font-medium"
                    to={`${VIEWS.SIGN_UP}?plan=${item?.price?.id}`}
                  >
                    Sign up for{" "}
                    {item?.product?.name.charAt(0).toUpperCase() +
                      item?.product?.name.slice(1).toLowerCase()}
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
        <Footer />
      </div>
      {isLoading ? <LoadingSpinner /> : null}
      <ToastContainer />
    </React.Fragment>
  );
}
