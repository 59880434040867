import React, { useEffect, useState } from "react";
import LoadingSpinner from "../../components/loading-spinner.component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import { VIEWS } from "../../routes/routes";
import { useScrollToTop } from "../../common/hooks/use-scroll-to-top";
import { useAuth } from "../../common/providers/user.provider";

export default function UserPrivacyPolicy() {
  useScrollToTop();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <React.Fragment>
      <div className="w-full min-h-screen flex flex-col justify-start items-start">
        <h1 className="text-2xl md:text3-xl lg:text-4xl xl:text-5xl text-slate-700 font-bold">
          <span
            onClick={() => navigate(-1)}
            className="cursor-pointer mr-2 lg:mr-10"
          >
            <i className="fa-solid fa-chevron-left"></i>
          </span>
          Privacy Policy
        </h1>
        <p className="text-left text-md leading-6 text-slate-600 mt-6 lg:mt-12">
          <strong>Effective Date:</strong> January 14, 2024
          <br></br>
          <br></br>
          Thank you for entrusting Revizite with your privacy. This Privacy
          Policy is designed to provide you with comprehensive insights into how
          we collect, utilize, and safeguard your personal information as you
          engage with our platform. By accessing and utilizing our services, you
          signify your consent to the terms outlined herein.
        </p>
        <ol className="list-decimal pl-4 lg:pl-12 mt-5">
          <li className="font-normal text-slate-600 text-md leading-normal lg:leading-10 text-left mt-5">
            <strong>Information Collection</strong>
            <br></br>
            At Revizite, we gather various types of information to enhance the
            quality and functionality of our services:
            <ol className="list-disc pl-2 lg:pl-8">
              <li>
                <b>Personal Information:</b> This encompasses but is not limited
                to your name, email address, contact details, and any other
                identifiers you provide voluntarily.
              </li>
              <li>
                <b>Usage Information:</b> We collect data pertaining to your
                interactions with our platform, including but not limited to
                browsing patterns, session duration, and feature usage.
              </li>
              <li>
                <b>Device Information:</b> Information such as device type,
                operating system, browser type, and IP address may be collected
                to optimize your experience and ensure platform compatibility.
              </li>
            </ol>
          </li>
          <li className="font-normal text-slate-600 text-md leading-normal lg:leading-10 text-left mt-5">
            <strong>Utilization of Your Information</strong>
            <br></br>
            At Revizite, we gather various types of information to enhance the
            quality and functionality of our services:
            <ol className="list-disc pl-2 lg:pl-8">
              <li>
                <b>Service Provision:</b> To deliver, maintain, and optimize the
                functionality of our services, ensuring seamless user
                experiences.
              </li>
              <li>
                <b>Communication:</b> We may use your information to communicate
                with you regarding updates, notifications, service
                announcements, and relevant marketing materials.
              </li>
              <li>
                <b>Personalization:</b> Your information enables us to tailor
                our services to your preferences, providing personalized
                content, recommendations, and user experiences.
              </li>
            </ol>
          </li>
          <li className="font-normal text-slate-600 text-md leading-normal lg:leading-10 text-left mt-5">
            <strong>Data Security</strong>
            <br></br>
            At Revizite, the security of your data is paramount. We employ
            robust measures to safeguard your information against unauthorized
            access, disclosure, alteration, or destruction:
            <ol className="list-disc pl-2 lg:pl-8">
              <li>
                <b>Encryption:</b> We utilize industry-standard encryption
                protocols to secure data transmission and storage, ensuring the
                confidentiality and integrity of your information.
              </li>
              <li>
                <b>Access Controls:</b> Access to your personal information is
                restricted to authorized personnel with a legitimate need to
                access such data.
              </li>
              <li>
                <b>Regular Audits and Assessments:</b> We conduct periodic
                security audits and assessments to identify and address
                vulnerabilities, ensuring ongoing compliance with industry best
                practices and regulatory standards.
              </li>
            </ol>
          </li>
          <li className="font-normal text-slate-600 text-md leading-normal lg:leading-10 text-left mt-5">
            <strong>Cookies and Tracking Technologies</strong>
            <br></br>
            We affirm that we do not employ cookies or similar tracking
            technologies on our platform. However, certain third-party services
            integrated into our platform may utilize cookies or tracking
            technologies, subject to their respective privacy policies.
          </li>
          <li className="font-normal text-slate-600 text-md leading-normal lg:leading-10 text-left mt-5">
            <strong>GDPR Compliance</strong>
            <br></br>
            Revizite is committed to adhering to the principles and requirements
            set forth by the General Data Protection Regulation (GDPR):
            <ol className="list-disc pl-2 lg:pl-8">
              <li>
                <b>Legal Basis:</b> Our data processing activities are conducted
                based on your explicit consent or as necessary for the
                performance of our contractual obligations.
              </li>
              <li>
                <b>Data Subject Rights:</b> You retain the rights afforded to
                you under the GDPR, including the right to access, rectify,
                restrict processing, and delete your personal information.
              </li>
            </ol>
          </li>
          <li className="font-normal text-slate-600 text-md leading-normal lg:leading-10 text-left mt-5">
            <strong>Third-Party Services</strong>
            <br></br>
            We may engage third-party service providers to facilitate various
            aspects of our operations, including but not limited to hosting,
            analytics, and customer support. These third-party services are
            governed by their respective privacy policies, and we encourage you
            to review them for further clarity.
          </li>
          <li className="font-normal text-slate-600 text-md leading-normal lg:leading-10 text-left mt-5">
            <strong>Modifications to Privacy Policy</strong>
            <br></br>
            We reserve the right to amend or update this Privacy Policy from
            time to time:
            <ol className="list-disc pl-2 lg:pl-8">
              <li>
                <b>Notification of Changes:</b> Any modifications to this
                Privacy Policy will be communicated to you via email or
                prominently displayed within our platform.
              </li>
              <li>
                <b>Continued Usage:</b> Your continued use of our services
                following the implementation of changes constitutes your
                acceptance of such changes.
              </li>
            </ol>
          </li>
          <li className="font-normal text-slate-600 text-md leading-normal lg:leading-10 text-left mt-5">
            <strong>Contact Information</strong>
            <br></br>
            If you have any questions, concerns, or inquiries regarding this
            Privacy Policy or our data practices, please contact us at{" "}
            <a
              href="mailto:team@revizite.com"
              className="text-blue-600 hover:underline"
            >
              team@revizite.com
            </a>
            . Our dedicated team is committed to addressing your concerns in a
            timely and transparent manner.
          </li>
        </ol>
        <p className="text-slate-600 text-md mt-20">
          Thank you for choosing{" "}
          <Link to={VIEWS.HOME} className="text-blue-600 hover:underline">
            Revizite
          </Link>
          . We remain steadfast in our commitment to protecting your privacy and
          providing you with exceptional service experiences.
        </p>
      </div>

      <div className="h-32"></div>
      {isLoading ? <LoadingSpinner /> : null}
      <ToastContainer />
    </React.Fragment>
  );
}
