import axios from "axios";
import { ENDPOINT } from "./api-endpoints.routes";

// Create customer API call
export const createCustomer = async (token: any) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  try {
    const response = await axios.post(
      `${ENDPOINT.PAYMENT.CUSTOM_CREATE_CUSTOMER}`,
      {},
      config
    );
    return { success: true, data: response.data };
  } catch (error: any) {
    return { success: false, error: error.response.data.message };
  }
};

// Create checkout API call
export const createCheckout = async (token: any, priceId: string) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  try {
    const response = await axios.post(
      `${ENDPOINT.PAYMENT.CUSTOM_CREATE_CHECKOUT}`,
      { priceId },
      config
    );
    return { success: true, data: response.data };
  } catch (error: any) {
    return { success: false, error: error.response.data.message };
  }
};

// Cancel subscription API call
export const cancelSubscription = async (token: any) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  try {
    const response = await axios.post(
      `${ENDPOINT.PAYMENT.CUSTOM_CANCEL_SUBSCRIPTION}`,
      {},
      config
    );
    return { success: true, data: response.data };
  } catch (error: any) {
    return { success: false, error: error.response.data.message };
  }
};
