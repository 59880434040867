import React, { useEffect, useState } from "react";
import LoadingSpinner from "../../components/loading-spinner.component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InputField from "../../components/input-field.component";
import Button from "../../components/button.component";
import ChangePwForm from "../../components/dashboard/forms/change-pw-form.component";
import EditGeneralDetailsForm from "../../components/dashboard/forms/edit-general-details-form";
import DeleteAccountForm from "../../components/dashboard/forms/delete-account-form";
import { Link } from "react-router-dom";
import { VIEWS } from "../../routes/routes";
import ProfileImageUploadForm from "../../components/dashboard/forms/profile-image-upload-form";
import { useAuth } from "../../common/providers/user.provider";
import { PricingPlans } from "../../common/enums/pricing-plan.enum";

export default function Settings() {
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <React.Fragment>
      <h1 className="text-5xl text-slate-700 font-bold">Account Settings</h1>
      <div className="overflow-hidden">
        <ProfileImageUploadForm />
        <EditGeneralDetailsForm />
        <ChangePwForm />
        <div className="w-full h-fit bg-blue-50 p-10 rounded-xl my-10 relative">
          <h1 className="text-xl font-semibold mt-5 mb-10 text-slate-600">
            Billing and Subscription
          </h1>
          <p className="text-sm text-slate-500 font-semibold mb-10 flex items-center">
            Your current subscription plan on Revizite
            <span
              className={`px-5 py-2 w-fit rounded-full cursor-default ${
                user?.pricingPlan === PricingPlans.PRO
                  ? "bg-blue-500 hover:bg-blue-600"
                  : "bg-orange-300 hover:bg-orange-400"
              } text-white ml-3`}
            >
              {user?.pricingPlan === PricingPlans.PRO ? "Pro" : "Free"}
            </span>
          </p>
          <Link
            to={VIEWS.USER_SUBSCRIPTIONS}
            className="absolute bottom-6 right-10 text-blue-600 text-md flex items-center font-semibold"
          >
            View more
            <i className="fa-solid fa-circle-arrow-right text-2xl ml-2"></i>
          </Link>
        </div>
        <DeleteAccountForm />
      </div>
      <div className="h-32"></div>
      {isLoading ? <LoadingSpinner /> : null}
      <ToastContainer />
    </React.Fragment>
  );
}
