import React, { useEffect, useState } from "react";
import LoadingSpinner from "../../components/loading-spinner.component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import { VIEWS } from "../../routes/routes";
import { useScrollToTop } from "../../common/hooks/use-scroll-to-top";
import { useAuth } from "../../common/providers/user.provider";

export default function UserHelp() {
  useScrollToTop();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <React.Fragment>
      <div className="w-full flex flex-col justify-center items-center">
        <h1 className="text-center text-4xl lg:text-6xl font-bold text-blue-950 leading-snug mt-24 lg:mt-10">
          Need Assistance?
        </h1>
        <h2 className="text-center text-2xl lg:text-4xl font-semibold text-blue-950 leading-normal mt-20">
          Contact Our Support Team
        </h2>
        <p className="text-center text-lg text-gray-400 leading-snug mt-6">
          If you have any questions, concerns, or need assistance with Revizite,
          our support team is here to help.
        </p>
        <p className="text-center text-lg text-gray-400 leading-snug mt-2">
          Feel free to reach out to us via email at{" "}
          <a
            href="mailto:team@revizite.com"
            className="text-blue-600 hover:underline"
          >
            team@revizite.com
          </a>
        </p>
      </div>
      <div className="h-32"></div>
      {isLoading ? <LoadingSpinner /> : null}
      <ToastContainer />
    </React.Fragment>
  );
}
