import React, { useEffect, useState } from "react";
import { Link, useNavigate, redirect } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingSpinner from "../../components/loading-spinner.component";
import { useScrollToTop } from "../../common/hooks/use-scroll-to-top";
import TopNavbar from "../../components/top-navbar.component";
import InputField from "../../components/input-field.component";
import Button from "../../components/button.component";
import { VIEWS } from "../../routes/routes";
import Footer from "../../components/footer.component";
import coverImage from "../../common/assets/images/sign_up.png";
import { signUpUser } from "../../services/auth-api.service";
import useToken from "../../hooks/useToken";
import ReactGA from "react-ga";

export default function SignUp() {
  useScrollToTop();
  const navigate = useNavigate();
  const { token, setToken, clearToken } = useToken();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [password, setPassword] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const checkEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const [isLoading, setIsLoading] = useState(false);

  //If the token is available public pages are unavailable
  useEffect(() => {
    if (token) {
      navigate(VIEWS.USER_DASHBOARD);
    }
  }, [token]);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleFormAction = async () => {
    if (email === "") {
      toast.error("Please enter email");
    } else if (!checkEmail.test(email)) {
      toast.error("Please enter valid email");
    } else if (firstName === "") {
      toast.error("Please enter first name");
    } else if (lastName === "") {
      toast.error("Please enter last name");
    } else if (password === "") {
      toast.error("Please enter password");
    } else if (!isChecked) {
      toast.error("Please accept the terms and conditions");
    } else {
      try {
        setIsLoading(true);

        /* Google analytics data collect point ***********************************/
        ReactGA.event({
          category: "User Interaction",
          action: "Clicked Sign-in Button",
        });
        /* Google analytics data collect point ***********************************/

        const response = await signUpUser(
          firstName,
          lastName,
          email,
          company,
          password
        );
        if (response.success) {
          console.log("RESPONSE", response?.data);
          setFirstName("");
          setLastName("");
          setEmail("");
          setCompany("");
          setPassword("");
          setIsLoading(false);
          toast.success(response.data?.message);
          setTimeout(() => {
            navigate(
              `${VIEWS.VERIFY_OTP}?email=${response?.data?.data?.email}`
            );
          }, 2000);
        } else {
          setIsLoading(false);
          toast.error(response.error);
        }
      } catch (error: any) {
        setIsLoading(false);
      }
    }
  };

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      handleFormAction();
    }
  };

  return (
    <React.Fragment>
      <div className="flex flex-col min-h-screen">
        <TopNavbar
          btnName={"Sign In"}
          btnAction={() => navigate(VIEWS.SIGN_IN)}
        />
        <div className="w-full flex flex-col md:flex-row justify-center items-center min-h-screen mt-20">
          {/* Left side container */}
          <div className="w-full md:w-full h-screen bg-amber-300 relative hidden md:flex justify-center items-center">
            <button
              onClick={() => navigate(-1)}
              className="text-white absolute top-16 left-16 text-4xl"
            >
              <i className="fa-solid fa-chevron-left"></i>
            </button>
            <img src={coverImage} alt="" className="w-2/3 h-auto" />{" "}
          </div>
          {/* Right side container */}
          <div className="w-full md:w-3/4 h-full bg-white flex justify-center items-start">
            <div className="w-full bg-light p-5 rounded-xl mx-10 sm:mx-20 md:mx-5 lg:mx-20 xl:mx-32">
              <h1 className="text-slate-800 font-bold text-center text-3xl mt-4 mb-6">
                Create free account
              </h1>
              <InputField
                icon="fa-solid fa-envelope"
                type="email"
                label="Email *"
                placeholder="Enter your email"
                value={email}
                onChange={setEmail}
                onKeyDown={handleKeyPress}
              />
              <InputField
                icon="fa-solid fa-user-large"
                type="text"
                label="First name *"
                placeholder="Enter your first name"
                value={firstName}
                onChange={setFirstName}
                onKeyDown={handleKeyPress}
              />
              <InputField
                icon="fa-solid fa-user-large"
                type="text"
                label="Last name *"
                placeholder="Enter your last name"
                value={lastName}
                onChange={setLastName}
                onKeyDown={handleKeyPress}
              />
              <InputField
                icon="fa-solid fa-building"
                type="text"
                label="Company name (optional)"
                placeholder="Enter your company name"
                value={company}
                onChange={setCompany}
                onKeyDown={handleKeyPress}
              />
              <InputField
                icon="fa-solid fa-lock"
                type="password"
                label="Password *"
                placeholder="Enter password"
                value={password}
                onChange={setPassword}
                onKeyDown={handleKeyPress}
              />
              <div className="w-full my-8 font-normal flex flex-row items-center justify-start ml-2">
                <input
                  type="checkbox"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  className="mr-2 w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded"
                />
                <label className="text-gray-700 text-sm font-semibold text-left ml-2">
                  I accept the{" "}
                  <Link
                    to={VIEWS.TERMS_CONDITIONS}
                    className="text-blue-600 cursor-pointer font-semibold underline hover:text-blue-800"
                  >
                    terms and conditions
                  </Link>
                </label>
              </div>
              <Button
                name={"Sign up to Revizite"}
                handleAction={handleFormAction}
              />
              <p className="block text-gray-700 text-sm font-semibold text-center mt-5 mb-4">
                Already have an account?{" "}
                <Link
                  to={VIEWS.SIGN_IN}
                  className="text-blue-800 hover:underline"
                >
                  Sign in
                </Link>
              </p>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      {isLoading ? <LoadingSpinner /> : null}
      <ToastContainer />
    </React.Fragment>
  );
}
