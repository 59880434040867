import React from "react";
import { Link } from "react-router-dom";
import { VIEWS } from "../routes/routes";
import logo from "../common/assets/images/logo_white.svg";

export default function Footer() {
  return (
    <React.Fragment>
      <footer className="bg-primary-dark w-full">
        <div className="w-full max-w-screen-xl mx-auto p-8">
          <div className="flex flex-col items-center justify-center sm:flex-row sm:items-start sm:justify-between">
            <div className="mb-4 sm:mb-0 text-center sm:text-left">
              <Link
                to={VIEWS.HOME}
                className="text-2xl font-semibold whitespace-nowrap dark:text-white"
              >
                <img src={logo} alt="" className="mx-auto sm:mx-0" />{" "}
              </Link>
              <span className="block text-sm text-gray-500 text-center mt-4 sm:text-left">
                © {new Date().getFullYear()}{" "}
                <Link
                  to={VIEWS.HOME}
                  className="hover:underline cursor-pointer"
                >
                  Revizite
                </Link>
                . All Rights Reserved.
              </span>
            </div>
            <ul className="flex flex-wrap items-center mb-6 text-sm font-medium text-white">
              <li>
                <Link
                  to={VIEWS.HELP}
                  className="mr-4 md:mr-6 hover:text-slate-300"
                >
                  Help
                </Link>
              </li>
              <li>
                <Link
                  to={VIEWS.TERMS_CONDITIONS}
                  className="mr-4 md:mr-6 hover:text-slate-300"
                >
                  Terms & Conditions
                </Link>
              </li>
              <li>
                <Link
                  to={VIEWS.PRIVACY_POLICY}
                  className="mr-4 md:mr-6 hover:text-slate-300"
                >
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
}
