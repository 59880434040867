import axios from "axios";
import { ENDPOINT } from "./api-endpoints.routes";

// Get single user API call
export const getSingleUser = async (token: string, id: string) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  try {
    const response = await axios.get(
      `${ENDPOINT.USER.ROOT_WITH_ID(id)}`,
      config
    );
    return { success: true, data: response.data };
  } catch (error: any) {
    return { success: false, error: error.response.data.message };
  }
};

// Update user API call
interface UpdateUserPayload {
  firstName?: string;
  lastName?: string;
  company?: string;
}
export const updateUser = async (
  token: any,
  id: string,
  firstName: string,
  lastName: string,
  company: string
) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  // Build the payload based on provided values
  const payload: UpdateUserPayload = {};
  if (firstName.trim() !== "") {
    payload.firstName = firstName;
  }
  if (lastName.trim() !== "") {
    payload.lastName = lastName;
  }
  if (company.trim() !== "") {
    payload.company = company;
  }

  try {
    const response = await axios.put(
      `${ENDPOINT.USER.ROOT_WITH_ID(id)}`,
      payload,
      config
    );
    return { success: true, data: response.data };
  } catch (error: any) {
    return { success: false, error: error.response.data.message };
  }
};

// Update user profile image API call
export const updateUserProfileImage = async (
  token: any,
  id: string,
  formData: FormData
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };
  try {
    const response = await axios.put(
      `${ENDPOINT.USER.ROOT_WITH_ID(id)}`,
      formData,
      config
    );
    return { success: true, data: response.data };
  } catch (error: any) {
    return { success: false, error: error.response.data.message };
  }
};

// Delete user API call
export const deleteUser = async (token: any, id: string) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  try {
    const response = await axios.delete(
      `${ENDPOINT.USER.ROOT_WITH_ID(id)}`,
      config
    );
    return { success: true, data: response.data };
  } catch (error: any) {
    return { success: false, error: error.response.data.message };
  }
};
