import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../common/assets/images/revize_logo_whiteandblue.svg";
import { VIEWS } from "../../routes/routes";
import useToken from "../../hooks/useToken";

const SidebarComponent = () => {
  const navigate = useNavigate();
  const { token, setToken, clearToken } = useToken();

  // Handling the active link
  const { pathname } = useLocation();
  const [activeLink, setActiveLink] = useState("");

  useEffect(() => {
    const path = pathname.split("/")[2];
    setActiveLink(path);
  }, [pathname]);

  const handleSignOut = () => {
    clearToken();
    navigate(VIEWS.HOME);
  };

  return (
    <div className="hidden lg:block bg-primary-dark text-white h-full min-h-screen w-72 py-4 fixed top-0 left-0 z-40 items-center">
      <div className="flex flex-col justify-center items-center mt-14 mb-24 px-10">
        <img
          src={logo}
          alt=""
          className="w-full h-auto cursor-pointer"
          onClick={() => navigate(VIEWS.USER_DASHBOARD)}
        />
      </div>
      <ul className="flex flex-col justify-between h-auto">
        <Link to={VIEWS.USER_DASHBOARD}>
          <li
            className={`px-6 py-4 hover:bg-blue-800 cursor-pointer mx-5 my-2 rounded-lg ${
              activeLink === "dashboard" ? "bg-blue-800" : "bg-inherit"
            }`}
          >
            <i className="fa-solid fa-file-circle-plus mr-2 text-xl"></i>
            Create Review
          </li>
        </Link>
        <Link to={VIEWS.USER_DOCUMENTS}>
          <li
            className={`px-6 py-4 hover:bg-blue-800 cursor-pointer mx-5 my-2 rounded-lg ${
              activeLink === "documents" ? "bg-blue-800" : "bg-inherit"
            }`}
          >
            <i className="fa-solid fa-file-lines mr-2 text-xl"></i> My Documents
          </li>
        </Link>
        <Link to={VIEWS.USER_SETTINGS}>
          <li
            className={`px-6 py-4 hover:bg-blue-800 cursor-pointer mx-5 my-2 rounded-lg ${
              activeLink === "settings" ? "bg-blue-800" : "bg-inherit"
            }`}
          >
            <i className="fa-solid fa-gear mr-2 text-xl"></i> Account Settings
          </li>
        </Link>
        <li
          className={`px-6 py-4 hover:bg-blue-800 cursor-pointer mx-5 my-2 rounded-lg`}
          onClick={() => handleSignOut()}
        >
          <i className="fa-solid fa-arrow-right-from-bracket mr-2 text-xl"></i>
          Log out
        </li>
      </ul>
      <div className="flex flex-col justify-center items-start absolute bottom-10 w-full">
        <ul className="flex flex-col items-start text-sm font-medium text-gray-200 px-12 list-disc">
          <li className="my-1">
            <Link
              to={VIEWS.USER_TERMS_CONDITIONS}
              className="mr-4 md:mr-6 hover:text-gray-400"
            >
              Terms & Conditions
            </Link>
          </li>
          <li className="my-1">
            <Link
              to={VIEWS.USER_PRIVACY_POLICY}
              className="mr-4 md:mr-6 hover:text-gray-400"
            >
              Privacy Policy
            </Link>
          </li>
        </ul>
        <span className="block text-xs text-gray-400 text-center mt-5 sm:text-left px-5">
          © {new Date().getFullYear()}{" "}
          <Link
            to={VIEWS.USER_DASHBOARD}
            className="hover:underline cursor-pointer"
          >
            Revizite
          </Link>
          . All Rights Reserved.
        </span>
      </div>
    </div>
  );
};

export default SidebarComponent;
