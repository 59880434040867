// Base URL
const BASE_URL = process.env.REACT_APP_BACKEND_API;

// API Endpoints
export const ENDPOINT = {
  AUTH: {
    ROOT: `${BASE_URL}auth`,
    SIGN_UP: `${BASE_URL}auth/sign-up`,
    SIGN_IN: `${BASE_URL}auth/sign-in`,
    VERIFY_OTP: `${BASE_URL}auth/verify-otp`,
    GENERATE_OTP: `${BASE_URL}auth/generate-otp`,
    FORGOT_PASSWORD: `${BASE_URL}auth/forgot-password`,
    RESET_PASSWORD: `${BASE_URL}auth/reset-password`,
    CHANGE_PASSWORD: `${BASE_URL}auth/change-password`,
  },
  USER: {
    ROOT: `${BASE_URL}user`,
    ROOT_WITH_ID: (id: string) => `${BASE_URL}user/${id}`,
  },
  DOCUMENT: {
    ROOT: `${BASE_URL}document`,
    ROOT_WITH_ID: (id: string) => `${BASE_URL}document/${id}`,
    CUSTOM_GET_DOCUMENTS_BY_USER: (
      id: string,
      currentPage: number,
      pageSize: number
    ) => `${BASE_URL}document/user/${id}?page=${currentPage}&take=${pageSize}`,
  },
  LOGGER: {
    CUSTOM_UPDATE_DOCUMENT_LOG: (id: string) => `${BASE_URL}log/document/${id}`,
  },
  PAYMENT: {
    ROOT: `${BASE_URL}payment`,
    ROOT_WITH_ID: (id: string) => `${BASE_URL}payment/${id}`,
    CUSTOM_CREATE_CUSTOMER: `${BASE_URL}payment/customer`,
    CUSTOM_CREATE_CHECKOUT: `${BASE_URL}payment/checkout`,
    CUSTOM_CANCEL_SUBSCRIPTION: `${BASE_URL}payment/cancel-subscription`,
  },
  SUBSCRIPTIONS: {
    ROOT: `${BASE_URL}subscriptions`,
    ROOT_WITH_ID: (id: string) => `${BASE_URL}subscriptions/${id}`,
  },
  OTP_FOR_NON_REGISTER_USERS: {
    VERIFY: `${BASE_URL}otp/verify`,
    GENERATE: `${BASE_URL}otp/generate`,
  },
};
