import axios, { AxiosProgressEvent, AxiosResponse } from "axios";
import { ENDPOINT } from "./api-endpoints.routes";

// Create document API call
export const createDocument = async (formData: FormData) => {
  try {
    const response = await axios.post(`${ENDPOINT.DOCUMENT.ROOT}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      timeout: 600000
    });

    return { success: true, data: response.data };
  } catch (error: any) {
    return {
      success: false,
      error: error.response?.data?.message,
    };
  }
};

// Get documents by user API call
export const getDocumentsByUser = async (
  token: any,
  userId: string,
  currentPage: number,
  pageSize: number
) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  try {
    const response = await axios.get(
      `${ENDPOINT.DOCUMENT.CUSTOM_GET_DOCUMENTS_BY_USER(
        userId,
        currentPage,
        pageSize
      )}`,
      config
    );
    return { success: true, data: response.data };
  } catch (error: any) {
    return {
      success: false,
      error: error.response?.data?.message,
    };
  }
};

// Delete document by user API call
export const deleteDocument = async (token: any, documentId: string) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  try {
    const response = await axios.delete(
      `${ENDPOINT.DOCUMENT.ROOT_WITH_ID(documentId)}`,
      config
    );
    return { success: true, data: response.data };
  } catch (error: any) {
    return {
      success: false,
      error: error.response?.data?.message,
    };
  }
};
