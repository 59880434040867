import axios from "axios";
import { ENDPOINT } from "./api-endpoints.routes";

// Login user API call
export const signInUser = async (email: string, password: string) => {
  try {
    const response = await axios.post(`${ENDPOINT.AUTH.SIGN_IN}`, {
      email,
      password,
    });
    return { success: true, data: response.data };
  } catch (error: any) {
    return { success: false, error: error.response.data.message };
  }
};

// Register new user API call
export const signUpUser = async (
  firstName: string,
  lastName: string,
  email: string,
  company: string | null,
  password: string
) => {
  try {
    const response = await axios.post(`${ENDPOINT.AUTH.SIGN_UP}`, {
      firstName,
      lastName,
      email,
      company,
      password,
    });
    return { success: true, data: response.data };
  } catch (error: any) {
    return { success: false, error: error.response.data.message };
  }
};

// Verify OTP API call
export const generateOtp = async (email: string) => {
  try {
    const response = await axios.post(`${ENDPOINT.AUTH.GENERATE_OTP}`, {
      email,
    });
    return { success: true, data: response.data };
  } catch (error: any) {
    return { success: false, error: error.response.data.message };
  }
};

// Verify OTP API call
export const verifyOtp = async (email: string, otp: number) => {
  try {
    const response = await axios.put(`${ENDPOINT.AUTH.VERIFY_OTP}`, {
      email,
      otp,
    });
    return { success: true, data: response.data };
  } catch (error: any) {
    return { success: false, error: error.response.data.message };
  }
};

// Change password API call
export const changePassword = async (
  token: string,
  oldPassword: string,
  password: string,
  confirmPassword: string
) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  try {
    const response = await axios.put(
      `${ENDPOINT.AUTH.CHANGE_PASSWORD}`,
      {
        oldPassword,
        password,
        confirmPassword,
      },
      config
    );
    return { success: true, data: response.data };
  } catch (error: any) {
    return { success: false, error: error.response.data.message };
  }
};

// Forgot password API call
export const forgotPassword = async (email: string) => {
  try {
    const response = await axios.post(`${ENDPOINT.AUTH.FORGOT_PASSWORD}`, {
      email,
    });
    return { success: true, data: response.data };
  } catch (error: any) {
    return { success: false, error: error.response.data.message };
  }
};

// Reset password API call
export const resetPassword = async (
  token: string,
  password: string,
  confirmPassword: string
) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  try {
    const response = await axios.put(
      `${ENDPOINT.AUTH.RESET_PASSWORD}`,
      {
        password,
        confirmPassword,
      },
      config
    );
    return { success: true, data: response.data };
  } catch (error: any) {
    return { success: false, error: error.response.data.message };
  }
};

// Get logged in user API call
export const getLoggedInUser = async (token: string) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  try {
    const response = await axios.get(`${ENDPOINT.AUTH.ROOT}`, config);
    return { success: true, data: response.data };
  } catch (error: any) {
    return { success: false, error: error.response.data.message };
  }
};
