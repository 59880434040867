import React, { useEffect, useState } from "react";
import LoadingSpinner from "../../components/loading-spinner.component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import { VIEWS } from "../../routes/routes";
import { useScrollToTop } from "../../common/hooks/use-scroll-to-top";
import { useAuth } from "../../common/providers/user.provider";

export default function UserTermsConditions() {
  useScrollToTop();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <React.Fragment>
      <div className="w-full min-h-screen flex flex-col justify-start items-start">
        <h1 className="text-2xl md:text3-xl lg:text-4xl xl:text-5xl text-slate-700 font-bold">
          <span
            onClick={() => navigate(-1)}
            className="cursor-pointer mr-2 lg:mr-10"
          >
            <i className="fa-solid fa-chevron-left"></i>
          </span>
          Terms & Conditions
        </h1>
        <p className="text-left text-md leading-6 text-slate-600 mt-6 lg:mt-12">
          <strong>Effective Date:</strong> January 14, 2024
          <br></br>
          <br></br>
          Welcome to Revizite, a document collaboration platform designed to
          streamline the review and proofreading process for businesses. By
          using our services, you agree to comply with the following Terms and
          Conditions. Please read them carefully.
        </p>
        <ol className="list-decimal pl-4 lg:pl-12 mt-5">
          <li className="font-normal text-slate-600 text-md leading-normal lg:leading-10 text-left mt-5">
            <strong>Acceptance of Terms</strong>
            <br></br>
            By accessing or using Revizite's Software as a Service (SaaS)
            platform ("the Service"), you agree to comply with and be bound by
            these Terms and Conditions. If you do not agree to these terms,
            please do not use the Service. We encourage users to periodically
            review these terms, as they may be updated to reflect changes in the
            Service or legal requirements.
          </li>
          <li className="font-normal text-slate-600 text-md leading-normal lg:leading-10 text-left mt-5">
            <strong>Use of the Service</strong>
            <span className="block pl-2 lg:pl-8 mt-2">
              <strong>2.1 Eligibility:</strong> You must be at least 18 years
              old to use the Service. By using the Service, you represent and
              warrant that you are at least 18 years old.
            </span>
            <span className="block pl-2 lg:pl-8 mt-2">
              <strong>2.2 Submission for Review:</strong>
              Users may submit files for review through the Service. By doing
              so, you grant Revizite the right to store and process the
              submitted data in accordance with our{" "}
              <Link
                to={VIEWS.PRIVACY_POLICY}
                className="text-blue-500 cursor-pointer font-semibold hover:underline hover:text-blue-800"
              >
                Privacy Policy
              </Link>
              . Your data security is our priority. We use advanced encryption
              methods to safeguard your files during storage and processing.
            </span>
            <span className="block pl-2 lg:pl-8 mt-2">
              <strong>2.3 Free Version:</strong> Users utilizing our free
              version must accept these Terms and Conditions upon submission.
              The free version allows users to submit files for review and
              receive a link to the file for annotations. This service is
              provided at no cost.
            </span>
            <span className="block pl-2 lg:pl-8 mt-2">
              <strong>2.4 Paid Version:</strong> Users opting for the paid
              version must accept these Terms and Conditions during the signup
              process. The paid version offers additional functionalities and
              features, including advanced collaboration features and an ad-free
              experience. See our pricing page for a detailed comparison.
            </span>
          </li>
          <li className="font-normal text-slate-600 text-md leading-normal lg:leading-10 text-left mt-5">
            <strong>User Responsibilities</strong>
            <span className="block pl-2 lg:pl-8 mt-2">
              <strong>3.1 Uploaded Content:</strong> Users are solely
              responsible for the content they upload through the Service.
              Revizite disclaims all liability for any unlawful or inappropriate
              content uploaded by users.
            </span>
            <span className="block pl-2 lg:pl-8 mt-2">
              <strong>3.2 Reporting Illegal Content:</strong> If any user
              becomes aware of illegal content, they must report it to
              Revizite's legal department at{" "}
              <a href="mailto:legal@revizite.com" className="text-blue-500">
                legal@revizite.com
              </a>
              , including the submission link and any relevant and available
              information. Revizite will respond within 30 days. Failure to
              comply with our content guidelines may result in account
              suspension or termination. We take reports seriously and
              investigate each case thoroughly. In cases involving illegal
              activities, we reserve the right to report such incidents to the
              appropriate law enforcement authorities.{" "}
            </span>
          </li>
          <li className="font-normal text-slate-600 text-md leading-normal lg:leading-10 text-left mt-5">
            <strong>User Accounts</strong>
            <span className="block pl-2 lg:pl-8 mt-2">
              <strong>4.1 Account Creation:</strong> To access certain features
              of the Service, you must create an account. You agree to provide
              accurate, current, and complete information during the
              registration process.
            </span>
            <span className="block pl-2 lg:pl-8 mt-2">
              <strong>4.2 Account Security:</strong> You are responsible for
              maintaining the confidentiality of your account credentials and
              for all activities that occur under your account.
            </span>
          </li>
          <li className="font-normal text-slate-600 text-md leading-normal lg:leading-10 text-left mt-5">
            <strong>Payment and Billing</strong>
            <span className="block pl-2 lg:pl-8 mt-2">
              <strong>5.1 Paid Subscriptions:</strong> If you choose to purchase
              a subscription, you agree to pay all applicable fees associated
              with the selected plan.
            </span>
            <span className="block pl-2 lg:pl-8 mt-2">
              <strong>5.2 Billing Information:</strong> You agree to provide
              accurate and complete billing information, including a valid
              payment method.
            </span>
            <span className="block pl-2 lg:pl-8 mt-2">
              <strong>5.3 Third-Party Services:</strong> Revizite utilizes
              third-party services for various functionalities. Stripe is used
              for payment processing. Read more in our{" "}
              <Link
                to={VIEWS.PRIVACY_POLICY}
                className="text-blue-500 cursor-pointer font-semibold hover:underline hover:text-blue-800"
              >
                Privacy Policy
              </Link>
              .<br></br>
              By using the Service, you agree to comply with the terms and
              conditions of these third-party services. We accept major credit
              cards and offer flexible billing cycles for your convenience. All
              payments are securely processed through Stripe, ensuring the
              highest standards of transaction security.
            </span>
            <span className="block pl-2 lg:pl-8 mt-2">
              <strong>5.4 Pricing Information:</strong>
              <span className="block pl-2 lg:pl-8 mt-2">
                <strong>
                  5.4.1 Prices may be displayed with or without Value Added Tax
                  (VAT).
                </strong>
              </span>
              <span className="block pl-2 lg:pl-8 mt-2">
                <strong>
                  5.4.2 The final price, inclusive of any applicable VAT or
                  other charges, will be clearly indicated at the checkout
                  before completing the payment.
                </strong>
              </span>
            </span>
            <span className="block pl-2 lg:pl-8 mt-2">
              <strong>5.5 Returns and Money Back Policy:</strong>
              <span className="block pl-2 lg:pl-8 mt-2">
                We offer a refund for purchases made within 14 days from the
                date of purchase.
              </span>
              <span className="block pl-2 lg:pl-8 mt-2">
                To request a refund, contact our customer support team at{" "}
                <a href="mailto:team@revizite.com" className="text-blue-500">
                  team@revizite.com
                </a>
                . Include your reasons for the refund and proof of purchase.
              </span>
              <span className="block pl-2 lg:pl-8 mt-2">
                If your request is approved, the refund will be processed within
                14 days.
              </span>
            </span>
          </li>
          <li className="font-normal text-slate-600 text-md leading-normal lg:leading-10 text-left mt-5">
            <strong>Intellectual Property</strong>
            <span className="block pl-2 lg:pl-8 mt-2">
              <strong>6.1 Ownership:</strong> Revizite retains all intellectual
              property rights in and to the Service. Users may not reproduce,
              distribute, or create derivative works without explicit
              permission.
            </span>
            <span className="block pl-2 lg:pl-8 mt-2">
              <strong>6.2 User Content:</strong> By submitting files for review,
              you grant Revizite a non-exclusive, worldwide, royalty-free
              license to use, reproduce, and display the content for the purpose
              of providing the Service. If you believe your copyright is
              infringed, please submit a detailed report to{" "}
              <a href="mailto:legal@revizite.com" className="text-blue-500">
                legal@revizite.com
              </a>
              , and we will promptly investigate and address the matter.
            </span>
          </li>
          <li className="font-normal text-slate-600 text-md leading-normal lg:leading-10 text-left mt-5">
            <strong>Termination</strong>
            <span className="block pl-2 lg:pl-8 mt-2">
              Revizite reserves the right to terminate or suspend your account
              and access to the Service at its sole discretion, without prior
              notice, for any reason. Should you wish to appeal an account
              suspension or termination, please contact our support team at{" "}
              <a href="mailto:team@revizite.com" className="text-blue-500">
                team@revizite.com
              </a>{" "}
              for further assistance.
            </span>
          </li>
          <li className="font-normal text-slate-600 text-md leading-normal lg:leading-10 text-left mt-5">
            <strong>Privacy</strong>
            <span className="block pl-2 lg:pl-8 mt-2">
              Use of the Service is also governed by our{" "}
              <Link
                to={VIEWS.PRIVACY_POLICY}
                className="text-blue-500 cursor-pointer font-semibold hover:underline hover:text-blue-800"
              >
                Privacy Policy
              </Link>
              . For detailed information regarding our privacy practices, please
              review our comprehensive{" "}
              <Link
                to={VIEWS.PRIVACY_POLICY}
                className="text-blue-500 cursor-pointer font-semibold hover:underline hover:text-blue-800"
              >
                Privacy Policy
              </Link>
              . If you have any questions or concerns about our privacy
              practices, please contact us at{" "}
              <a href="mailto:legal@revizite.com" className="text-blue-500">
                legal@revizite.com
              </a>
              .
            </span>
            <span className="block pl-2 lg:pl-8 mt-2">
              <strong>Email Marketing:</strong> You acknowledge and consent to
              the collection and retention of your email address for marketing
              and informational purposes related to our services. We assure you
              that your information will be handled securely and in compliance
              with applicable privacy laws and GDPR. You may receive periodic
              emails, and we commit to communicating with you responsibly. We do
              not share your email address with third parties. You can expect to
              receive communications from us at a frequency of once or more per
              month. In case of any changes to this, we will notify you
              accordingly. To manage your communication preferences or address
              any concerns, please utilize the unsubscribe link provided at the
              bottom of any email received from us. Additionally, if you have
              questions about the privacy and security of your information,
              please do not hesitate to contact us at{" "}
              <a href="mailto:legal@revizite.com" className="text-blue-500">
                legal@revizite.com
              </a>
              .
            </span>
          </li>
          <li className="font-normal text-slate-600 text-md leading-normal lg:leading-10 text-left mt-5">
            <strong>Changes to Terms and Conditions</strong>
            <span className="block pl-2 lg:pl-8 mt-2">
              Revizite reserves the right to update or modify these Terms and
              Conditions at any time. We understand the importance of
              transparency and any significant changes will be communicated to
              you via email, along with a summary of the modifications.
            </span>
          </li>
          <li className="font-normal text-slate-600 text-md leading-normal lg:leading-10 text-left mt-5">
            <strong>Contact Information</strong>
            <span className="block pl-2 lg:pl-8 mt-2">
              If you have any questions or concerns about these Terms and
              Conditions, please contact us at{" "}
              <a href="mailto:team@revizite.com" className="text-blue-500">
                team@revizite.com
              </a>
              . Our customer support team is dedicated to assisting you for any
              questions regarding Revizite. Please feel free to contact us at{" "}
              <a href="mailto:team@revizite.com" className="text-blue-500">
                team@revizite.com
              </a>
              .
            </span>
          </li>
          <li className="font-normal text-slate-600 text-md leading-normal lg:leading-10 text-left mt-5">
            <strong>User Feedback and Suggestions</strong>
            <span className="block pl-2 lg:pl-8 mt-2">
              Users are encouraged to provide feedback and suggestions for the
              improvement of the Service. By submitting feedback, you grant
              Revizite the right to use and incorporate such suggestions into
              the Service without any obligation.
            </span>
          </li>
          <li className="font-normal text-slate-600 text-md leading-normal lg:leading-10 text-left mt-5">
            <strong>Ownership of User-Generated Content</strong>
            <span className="block pl-2 lg:pl-8 mt-2">
              While users retain ownership of their submitted files,
              annotations, and feedback, Revizite is granted a license to
              display, distribute, and reproduce this content within the Service
              for the purpose of providing the product review and feedback
              functionality.
            </span>
          </li>
          <li className="font-normal text-slate-600 text-md leading-normal lg:leading-10 text-left mt-5">
            <strong>Availability of the Review Feature</strong>
            <span className="block pl-2 lg:pl-8 mt-2">
              Revizite reserves the right to modify, suspend, or discontinue any
              feature or part of the service at any time without prior notice.
              This includes, but is not limited to, the product review and
              feedback feature. Users will be notified of any significant
              changes to these features.
            </span>
          </li>
          <li className="font-normal text-slate-600 text-md leading-normal lg:leading-10 text-left mt-5">
            <strong>Responsiveness to User Feedback</strong>
            <span className="block pl-2 lg:pl-8 mt-2">
              Revizite is committed to reviewing user feedback and making
              reasonable efforts to address reported issues promptly. However,
              there is no guarantee that all feedback will result in specific
              changes or updates to the Service.
            </span>
          </li>
          <li className="font-normal text-slate-600 text-md leading-normal lg:leading-10 text-left mt-5">
            <strong>User Submissions, Feedback and Reviews</strong>
            <span className="block pl-2 lg:pl-8 mt-2">
              Users are responsible for the accuracy of the information provided
              in their submissions, feedback, and reviews. Revizite reserves the
              right to moderate or remove feedback and reviews that violate the
              terms and conditions, including but not limited to, fraudulent
              submissions or content not related to the document's review and
              feedback functionality.
            </span>
          </li>
          <li className="font-normal text-slate-600 text-md leading-normal lg:leading-10 text-left mt-5">
            <strong>Confidentiality of Beta Features</strong>
            <span className="block pl-2 lg:pl-8 mt-2">
              If Revizite introduces beta features for review, users agree not
              to disclose, reproduce, or share any information related to these
              features without explicit permission. Violation of this clause may
              result in termination of access to beta features.
            </span>
          </li>
          <li className="font-normal text-slate-600 text-md leading-normal lg:leading-10 text-left mt-5">
            <strong>Dispute Resolution</strong>
            <span className="block pl-2 lg:pl-8 mt-2">
              In the event of collaboration-related disputes, such as data
              synchronization delays or usability issues, we undertake internal
              resolution efforts. While we strive to address reported issues,
              users acknowledge using the platform at their own risk. Our team
              will investigate disputes, but we do not guarantee specific
              outcomes. Users are responsible for data safeguards. By using the
              Service, you acknowledge that you have read, understood, and agree
              to be bound by these Terms and Conditions.
            </span>
          </li>
        </ol>
        {/* <p className="text-left font-bold text-md leading-6 text-slate-600 mt-12">
            By using the Service, you acknowledge that you have read,
            understood, and agree to be bound by these Terms and Conditions.
          </p> */}
      </div>
      <div className="h-32"></div>
      {isLoading ? <LoadingSpinner /> : null}
      <ToastContainer />
    </React.Fragment>
  );
}
