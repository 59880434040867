import axios from "axios";
import { ENDPOINT } from "./api-endpoints.routes";

// Get all subscription plans API call
export const getAllSubscriptionPlans = async () => {
  try {
    const response = await axios.get(`${ENDPOINT.SUBSCRIPTIONS.ROOT}`);
    return { success: true, data: response.data };
  } catch (error: any) {
    return {
      success: false,
      error: error.response?.data?.message,
    };
  }
};
