import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, redirect, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingSpinner from "../../components/loading-spinner.component";
import { useScrollToTop } from "../../common/hooks/use-scroll-to-top";
import TopNavbar from "../../components/top-navbar.component";
import Button from "../../components/button.component";
import { VIEWS } from "../../routes/routes";
import Footer from "../../components/footer.component";
import backgroundImage from "../../common/assets/images/home.png";
import { DropEvent, FileRejection, useDropzone } from "react-dropzone";
import SelectField from "../../components/select-field.component";
import { ReminderValues } from "../../common/enums/reminder-date.enum";
import { createDocument } from "../../services/document-api.service";
import FormTextField from "../../components/form-text.component";
import FormInputField from "../../components/form-input.component";
import useToken from "../../hooks/useToken";
import { PricingPlans } from "../../common/enums/pricing-plan.enum";
import { UserRoles } from "../../common/enums/user-roles.enum";
import PopupComponent from "../../components/popup.component";
import OtpInputField from "../../components/otp-input-field.component";
import verifyOtp from "../auth/verify-otp";
import {
  generateOtpForNonRegisterUser,
  verifyOtpForNonRegisterUser,
} from "../../services/otp-non-register-user.service";

export default function Home() {
  useScrollToTop();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    file: null as File | null,
    senderEmail: "",
    receiverEmail: "",
    dueDate: "",
    reminder: "",
    message: "",
    isTermsAndConditionsAccepted: false,
    fileName: "",
  });
  const checkEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const [isLoading, setIsLoading] = useState(false);
  const { token, setToken, clearToken } = useToken();
  const [isChecked, setIsChecked] = useState(false);
  const [isOtpFormDisplay, setIsOtpFormDisplay] = useState(false);

  //Getting the token from the URL params
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isPopupDisplay: string = searchParams.get("isPopupDisplay") ?? "";
  const popUpHeading: string = searchParams.get("popUpHeading") ?? "";
  const popUpMessage: string = searchParams.get("popUpMessage") ?? "";
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupContent, setPopupContent] = useState({
    heading: "",
    message: "",
  });

  useEffect(() => {
    if (isPopupDisplay === "true") {
      setIsPopupOpen(true);
    }
    setPopupContent({
      heading: popUpHeading,
      message: popUpMessage,
    });
  }, [isPopupDisplay, popUpHeading, popUpMessage]);

  //If the token is available public pages are unavailable
  useEffect(() => {
    if (token) {
      navigate(VIEWS.USER_DASHBOARD);
    }
  }, [token]);

  //Handling the select options
  const [selectedOption, setSelectedOption] = useState("");
  const options = Object.values(ReminderValues).map((value) => ({
    value: value,
    label: value,
  }));
  const handleSelectChange = (value: string) => {
    setSelectedOption(value);
  };

  //Handle check box
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  //Handling the text input
  const handleInputChange = (name: string, value: any) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // FILE UPLOADING FUNCTIONALITIES GOES HERE===============================================================
  interface FileWithPreview extends File {
    preview: string;
  }

  //To selecting the single file only
  const [files, setFiles] = useState<FileWithPreview[]>([]);
  const [rejected, setRejected] = useState<File[]>([]);
  const [dropzoneDisabled, setDropzoneDisabled] = useState(false);

  // Define constants for file size limits
  const FREE_USER_FILE_LIMIT = 30 * 1024 * 1024; // 30 MB in bytes
  const PRO_USER_FILE_LIMIT = 100 * 1024 * 1024; // 100 MB in bytes
  const userSubscription = PricingPlans.FREE;

  const onDrop = useCallback(
    (
      acceptedFiles: File[],
      fileRejections: FileRejection[],
      event: DropEvent
    ) => {
      // Check if the dropzone is disabled (file already selected)
      if (dropzoneDisabled) {
        return;
      }

      // Ensure only one file is accepted
      if (acceptedFiles?.length === 1) {
        const file = acceptedFiles[0];

        // Checking the file size limitations
        const fileSize = file.size;
        const maxFileSize =
          userSubscription === PricingPlans.FREE
            ? FREE_USER_FILE_LIMIT
            : PRO_USER_FILE_LIMIT;
        if (fileSize > maxFileSize) {
          toast.error(
            `File size exceeds the limit. Maximum allowed file size is ${
              maxFileSize / (1024 * 1024)
            } MB for ${userSubscription} users.`
          );
          return;
        }

        setFiles((previousFiles) => [
          ...previousFiles,
          {
            ...acceptedFiles[0],
            preview: URL.createObjectURL(acceptedFiles[0]),
          },
        ]);

        // Read the file content as binary
        const reader = new FileReader();
        reader.onload = (e) => {
          const binaryData = e.target?.result;

          // Convert ArrayBuffer to Blob
          const blob = new Blob([binaryData as ArrayBuffer], {
            type: file.type,
          });

          // Update state with the file
          setFormData({
            ...formData,
            file: file,
            fileName: file.name,
          });

          // Append the Blob to FormData
          const formDataToSend = new FormData();
          formDataToSend.append("file", file);
          formDataToSend.append("senderEmail", formData.senderEmail);
          formDataToSend.append("receiverEmail", formData.receiverEmail);
          formDataToSend.append("dueDate", formData.dueDate);
          formDataToSend.append("reminder", formData.reminder);
          formDataToSend.append("message", formData.message);
          formDataToSend.append("fileName", formData.fileName);

          // Use formDataToSend in your API call
          console.log("formDataToSend:", formDataToSend);

          // Disable the dropzone after selecting a file
          setDropzoneDisabled(true);
        };

        // Read file content as binary
        reader.readAsArrayBuffer(file);
      }

      // Handle other rejected files
      if (fileRejections?.length) {
        setRejected((previousFiles) => [
          ...previousFiles,
          ...fileRejections.map((rejection) => rejection.file),
        ]);
      }
    },
    [dropzoneDisabled, formData, setRejected]
  );

  const removeFile = (name: string) => {
    setFiles((files) => files.filter((file) => file.name !== name));
    // Enable the dropzone after removing the file
    setDropzoneDisabled(false);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      "application/pdf": [".pdf", ".PDF"],
    },
    maxFiles: 1,
    onDrop,
  });

  useEffect(() => {
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  // SENDING THE EMAIL VERIFICATION & VERIFYING THE OTP FUNCTIONALITIES GOES HERE=============================================
  const handleSendingEmailVerification = async () => {
    const {
      file,
      senderEmail,
      receiverEmail,
      dueDate,
      reminder,
      message,
      fileName,
    } = formData;
    if (!file) {
      toast.error("Please select a document");
    } else if (senderEmail === "") {
      toast.error("Please enter sender email");
    } else if (!checkEmail.test(senderEmail)) {
      toast.error("Please enter valid sender email");
    } else if (receiverEmail === "") {
      toast.error("Please enter receiver email");
    } else if (!checkEmail.test(receiverEmail)) {
      toast.error("Please enter valid receiver email");
    } else if (dueDate === "") {
      toast.error("Please select due date");
    } else if (new Date(dueDate) > new Date(calculateMaxDueDate())) {
      toast.error(
        "Upgrade to Pro to set due dates longer ahead than one month"
      );
    } else if (message === "") {
      toast.error("Please write your review instructions");
    } else if (!isChecked) {
      toast.error("Please accept the terms and conditions");
    } else {
      try {
        setIsLoading(true);

        // console.log("CHECK MAX DUE DATE : ", new Date(calculateMaxDueDate()));
        // console.log("CHECK DUE DATE : ", new Date(dueDate));

        // Calling to the API service
        const response = await generateOtpForNonRegisterUser(
          formData.senderEmail
        );
        // Handling the response
        if (response.success) {
          setIsLoading(false);
          setIsOtpFormDisplay(true);
          toast.success(response.data?.message);
        } else {
          setIsLoading(false);
          toast.error(response.error);
        }
      } catch (error) {
        console.error("Error during form action:", error);
        setIsLoading(false);
      }
    }
  };

  // Handling the OTP verification
  const [otp, setOtp] = useState<number | undefined>();
  const handleVerifyOtp = async (event: any) => {
    if (otp === undefined) {
      toast.error("Please enter OTP");
    } else {
      try {
        setIsLoading(true);
        const response = await verifyOtpForNonRegisterUser(
          formData.senderEmail,
          otp
        );
        if (response.success) {
          setIsLoading(false);
          setOtp(undefined);
          handleFormAction(event);
        } else {
          setIsLoading(false);
          toast.error(response.error);
        }
      } catch (error: any) {
        setIsLoading(false);
      }
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event && event.key === "Enter") {
      handleVerifyOtp(event);
    }
  };

  // DOCUMENT SHARING FUNCTIONALITIES GOES HERE=============================================
  const handleFormAction = async (e: any) => {
    e.preventDefault();
    const {
      file,
      senderEmail,
      receiverEmail,
      dueDate,
      reminder,
      message,
      fileName,
    } = formData;
    if (!file) {
      toast.error("Please select a document");
    } else if (senderEmail === "") {
      toast.error("Please enter receiver email");
    } else if (!checkEmail.test(senderEmail)) {
      toast.error("Please enter valid receiver email");
    } else if (receiverEmail === "") {
      toast.error("Please enter receiver email");
    } else if (!checkEmail.test(receiverEmail)) {
      toast.error("Please enter valid receiver email");
    } else if (dueDate === "") {
      toast.error("Please select due date");
    } else if (message === "") {
      toast.error("Please write your review instructions");
    } else if (!isChecked) {
      toast.error("Please accept the terms and conditions");
    } else {
      try {
        setIsLoading(true);

        // // Check if the Geolocation API is available in the browser
        // if ("geolocation" in navigator) {
        //   navigator.geolocation.getCurrentPosition(
        //     async (position) => {
        //       const isTermsAndConditionsAccepted = isChecked;
        //       const latitude = position.coords.latitude.toString();
        //       const longitude = position.coords.longitude.toString();
        //       const reminder =
        //         selectedOption === ReminderValues.NoReminder
        //           ? ""
        //           : selectedOption;

        //       // Create FormData object to handle file upload
        //       const formData = new FormData();
        //       formData.append("file", file);
        //       formData.append("senderEmail", senderEmail);
        //       formData.append("receiverEmail", receiverEmail);
        //       formData.append("dueDate", dueDate);
        //       formData.append("reminder", reminder);
        //       formData.append("message", message);
        //       formData.append(
        //         "isTermsAndConditionsAccepted",
        //         isTermsAndConditionsAccepted.toString()
        //       );
        //       formData.append("latitude", latitude);
        //       formData.append("longitude", longitude);
        //       formData.append("fileName", fileName);

        //       // Calling to the API service
        //       const response = await createDocument(formData);

        //       // Handling the response
        //       if (response.success) {
        //         setFormData({
        //           file: null,
        //           senderEmail: "",
        //           receiverEmail: "",
        //           dueDate: "",
        //           reminder: "",
        //           message: "",
        //           isTermsAndConditionsAccepted: false,
        //           fileName: "",
        //         });
        //         setSelectedOption("");
        //         setFiles([]);
        //         window.scrollTo(0, 0);
        //         toast.success("Document transfer successfully.");
        //         setTimeout(() => {
        //           navigate(VIEWS.USER_DOCUMENTS);
        //         }, 2000);
        //       } else {
        //         toast.error(response.error);
        //       }
        //       setIsLoading(false);
        //     },
        //     (error) => {
        //       console.error("Error getting user location:", error);
        //       setIsLoading(false);
        //     }
        //   );
        // } else {
        //   console.error("Geolocation is not supported");
        //   setIsLoading(false);
        // }
        const isTermsAndConditionsAccepted = isChecked;
        // const latitude = position.coords.latitude.toString();
        // const longitude = position.coords.longitude.toString();
        const reminder =
          selectedOption === ReminderValues.NoReminder ? "" : selectedOption;

        // Create FormData object to handle file upload
        const formData = new FormData();
        formData.append("file", file);
        formData.append("senderEmail", senderEmail);
        formData.append("receiverEmail", receiverEmail);
        formData.append("dueDate", dueDate);
        formData.append("reminder", reminder);
        formData.append("message", message);
        formData.append(
          "isTermsAndConditionsAccepted",
          isTermsAndConditionsAccepted.toString()
        );
        // formData.append("latitude", latitude);
        // formData.append("longitude", longitude);
        formData.append("fileName", fileName);

        console.log("CHECK FINAL VALUES : ", formData);
        // Calling to the API service
        const response = await createDocument(formData);

        // Handling the response
        if (response.success) {
          setFormData({
            file: null,
            senderEmail: "",
            receiverEmail: "",
            dueDate: "",
            reminder: "",
            message: "",
            isTermsAndConditionsAccepted: false,
            fileName: "",
          });
          setSelectedOption("");
          setFiles([]);
          setIsOtpFormDisplay(false);
          window.scrollTo(0, 0);
          toast.success("Document transfer successfully.");
          setTimeout(() => {
            navigate(VIEWS.USER_DOCUMENTS);
          }, 2000);
        } else {
          toast.error(response.error);
        }
        setIsLoading(false);
      } catch (error) {
        console.error("Error during form action:", error);
        setIsLoading(false);
      }
    }
  };

  const parentDivStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  };

  // Set the max due date as 1 month ahead
  const calculateMaxDueDate = () => {
    const today = new Date();
    const oneMonthAhead = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      today.getDate()
    );
    const maxDueDate = oneMonthAhead.toISOString().split("T")[0];
    return maxDueDate;
  };

  return (
    <React.Fragment>
      <div
        className="flex flex-col min-h-screen p-5 lg:p-0"
        style={parentDivStyle}
      >
        <TopNavbar
          btnName={"Sign In"}
          btnAction={() => navigate(VIEWS.SIGN_IN)}
        />
        <div className="w-full flex flex-col md:flex-row justify-center items-center mt-20 min-h-screen">
          {/* Left side container */}
          <div className="w-full lg:w-1/2 lg:pl-12 xl:pl-24 h-auto flex justify-start items-center md:items-start flex-col">
            <h1
              className="text-center md:text-left text-4xl md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl font-bold text-blue-950 leading-normal lg:leading-snug mb-6 lg:mb-10"
              style={{ lineHeight: "1.5" }}
            >
              Simplifies the way professionals collaborate on
              <span className="text-white py-2 px-4 bg-blue-600 rounded-xl mx-2 inline-block">
                document
              </span>
              reviews
            </h1>
            <button
              onClick={() => navigate(VIEWS.SIGN_UP)}
              type="button"
              className="bg-primary-light hover:bg-violet-500 text-white rounded-xl text-lg px-5 py-3 lg:py-4 lg:px-8 text-center font-semibold w-max lg:w-auto mt-4 lg:mt-8"
            >
              Get started <i className="fa-solid fa-angle-right ml-2"></i>
            </button>
          </div>
          {/* Right side container */}
          {isOtpFormDisplay ? (
            <form className="w-full lg:w-fit bg-blue-50 p-4 rounded-xl my-10 mx-5 md:mx-5 lg:mx-32 h-fit">
              <h1 className="text-slate-800 font-bold text-center text-3xl my-14">
                Verify your email
              </h1>
              <p className="block text-gray-400 text-xs text-center my-6">
                Please enter the 6-digit one-time code you received in your
                email.
              </p>
              <OtpInputField onChange={setOtp} onKeyDown={handleKeyPress} />
              <div className="h-10"></div>
              <Button
                name={"Verify your email"}
                handleAction={handleVerifyOtp}
              />
            </form>
          ) : (
            <form className="w-full md:w-2/3 lg:w-1/3 bg-blue-50 p-4 rounded-xl my-10 mx-5 md:mx-5 lg:mx-32">
              <div>
                <div
                  {...getRootProps({
                    className: "custom-class",
                  })}
                >
                  <input {...getInputProps()} accept="application/pdf" />
                  <div className="flex flex-col items-center justify-center gap-4">
                    {isDragActive ? (
                      <div className="flex flex-col items-center justify-center py-5 h-36 text-center bg-white w-full rounded-xl cursor-pointer border-2 border-blue-600 border-dashed">
                        <i className="fa-solid fa-cloud-arrow-up text-blue-600 text-6xl mt-5" />
                        <p className="text-slate-400 text-sm mt-1">
                          Drop the PDF here ...
                        </p>
                      </div>
                    ) : (
                      <div className="flex flex-col items-center justify-center py-5 h-36 text-center bg-white w-full rounded-xl cursor-pointer border-2 border-gray-300 border-dashed">
                        <i className="fa-solid fa-cloud-arrow-up text-slate-200 text-6xl mt-5" />
                        <p className="text-slate-400 text-sm mt-1">
                          Drag and drop or click to upload PDF file
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                <section className="mt-2 mb-10">
                  {files.map((file: any, index: any) => (
                    <div
                      key={index.toString()}
                      className="w-full px-5 py-3 rounded-lg shadow-xm bg-slate-50 text-slate-700 flex flex-row items-center justify-between my-2"
                    >
                      <div className="w-full flex flex-row items-center justify-start">
                        <i className="fa-solid fa-file-pdf text-2xl" />
                        <p className="text-slate-700 text-sm font-medium ml-2">
                          {file?.path}
                        </p>
                      </div>
                      <button
                        type="button"
                        className="w-7 h-7 flex justify-center items-center transition-colors cursor-pointer"
                        onClick={() => removeFile(file.name)}
                      >
                        <i className="fa-regular fa-circle-xmark text-2xl" />
                      </button>
                    </div>
                  ))}
                </section>
              </div>
              <FormInputField
                icon="fa-solid fa-envelope"
                type="email"
                label="Your email *"
                placeholder="Enter your email"
                name="senderEmail"
                value={formData.senderEmail}
                onChange={(data: { name: string; value: any }) =>
                  handleInputChange(data.name, data.value)
                }
              />
              <FormInputField
                icon="fa-solid fa-envelope"
                type="email"
                label="Receiver email *"
                placeholder="Enter receiver email"
                name="receiverEmail"
                value={formData.receiverEmail}
                onChange={(data: { name: string; value: any }) =>
                  handleInputChange(data.name, data.value)
                }
              />
              <div className="flex flex-col lg:flex-row items-center justify-between w-full">
                <div className="w-full lg:mr-5">
                  <FormInputField
                    icon="fa-regular fa-calendar-days"
                    type="date"
                    label="Select the due date *"
                    placeholder="Due date"
                    name="dueDate"
                    value={formData.dueDate || "Select due date"}
                    onChange={(data: { name: string; value: any }) =>
                      handleInputChange(data.name, data.value)
                    }
                    max={calculateMaxDueDate()}
                  />
                </div>
                <div className="w-full">
                  <SelectField
                    icon="fa-solid fa-clock"
                    options={options}
                    value={selectedOption}
                    onChange={handleSelectChange}
                    disabled={false}
                    label="Select the reminder (Optional)"
                    placeholder={"Select Reminder"}
                  />
                </div>
              </div>
              <FormTextField
                label="Review instructions *"
                name="message"
                value={formData.message}
                onChange={(data: { name: string; value: any }) =>
                  handleInputChange(data.name, data.value)
                }
                rows={3}
              />
              <div className="w-full my-8 font-normal flex flex-row items-center justify-start ml-2">
                <input
                  type="checkbox"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  className="mr-2 w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded"
                />
                <label className="text-gray-700 text-sm font-semibold text-left ml-2">
                  I accept the{" "}
                  <Link
                    to={VIEWS.TERMS_CONDITIONS}
                    className="text-blue-600 cursor-pointer font-semibold underline hover:text-blue-800"
                  >
                    terms and conditions
                  </Link>
                </label>
              </div>
              <Button
                name={"Submit request"}
                handleAction={handleSendingEmailVerification}
              />
            </form>
          )}
        </div>
      </div>
      <Footer />
      {isLoading ? <LoadingSpinner /> : null}
      <ToastContainer />
      <PopupComponent
        popUpDisplay={isPopupOpen}
        handleClose={() => setIsPopupOpen(false)}
        handleOpen={() => setIsPopupOpen(true)}
        popUpTitle={""}
      >
        <div className="flex flex-col justify-center items-center my-5">
          <i className="fa-solid fa-circle-check text-8xl text-green-500 mb-10"></i>
          <h3 className="md:text-2xl text-base text-gray-900 font-semibold text-center mb-2">
            {popupContent.heading}
          </h3>
          <p>{popupContent.message}</p>
        </div>
      </PopupComponent>
    </React.Fragment>
  );
}
